var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"examination-topic-input-form"},[_c('base-button-plus',{staticClass:"add-topic-class",attrs:{"text":'項目区分追加'},on:{"click":function($event){return _vm.$emit('addTopicClass')}}}),_vm._l((_vm.topicClasses),function(topicClass){return _c('div',{key:topicClass.key,staticClass:"topic-class-wrapper"},[_c('text-input-form',{attrs:{"maxlength":255,"placeholder":'255文字まで',"requiredFlg":_vm.topicClasses.length > 1,"styles":{ width: '350px', marginRight: '20px' }},model:{value:(topicClass.name),callback:function ($$v) {_vm.$set(topicClass, "name", $$v)},expression:"topicClass.name"}},[_vm._v("項目区分名")]),_vm._l((topicClass.topics),function(topic){return _c('div',{key:topic.key,staticClass:"topic-wrapper",class:{ only: topicClass.topics.length === 1 }},[_c('div',{staticClass:"row flex name"},[_c('text-input-form',{attrs:{"maxlength":30,"placeholder":'30文字まで',"requiredFlg":true,"styles":{ width: '350px', marginRight: '20px' }},model:{value:(topic.name),callback:function ($$v) {_vm.$set(topic, "name", $$v)},expression:"topic.name"}},[_vm._v("項目名")]),_c('base-button-plus',{attrs:{"styles":{ width: '125px', marginRight: '20px' },"text":'項目追加'},on:{"click":function($event){return _vm.$emit('addTopic', topicClass.key)}}}),(topicClass.topics.length > 1 || _vm.topicClasses.length > 1)?_c('base-button-trash',{attrs:{"styles":{ width: '125px' }},on:{"click":function($event){return _vm.$emit('removeTopic', {
              topicClassKey: topicClass.key,
              topicKey: topic.key
            })}}}):_vm._e()],1),_c('div',{staticClass:"row examination-type"},[_c('select-box-form',{attrs:{"selectData":_vm.examinationTypeSelectData,"requiredFlg":true},on:{"input":function($event){return _vm.$emit('selectType', {
              topicClassKey: topicClass.key,
              topicKey: topic.key,
              typeId: $event
            })}},model:{value:(topic.typeId),callback:function ($$v) {_vm.$set(topic, "typeId", $$v)},expression:"topic.typeId"}},[_vm._v("入力タイプ")])],1),_c('div',{staticClass:"row unit"},[_c('text-input-form',{attrs:{"maxlength":30,"placeholder":'30文字まで',"requiredFlg":topic.typeId === 0,"styles":{ width: '350px' }},model:{value:(topic.unit),callback:function ($$v) {_vm.$set(topic, "unit", $$v)},expression:"topic.unit"}},[_vm._v("単位")])],1),_c('div',{staticClass:"row comment"},[_c('examination-topic-comment-row',{attrs:{"comments":topic.comments,"typeId":topic.typeId},on:{"addOption":function($event){return _vm.$emit('addOption', {
              topicClassKey: topicClass.key,
              topicKey: topic.key
            })},"removeOption":function($event){return _vm.$emit('removeOption', {
              topicClassKey: topicClass.key,
              topicKey: topic.key,
              optionKey: $event
            })}}})],1),_c('div',{staticClass:"row flex species"},[_c('div',{staticClass:"label"},[_c('span',{staticClass:"image-allow",attrs:{"data-test":"image-allow"},on:{"click":function($event){return _vm.$emit('toggleSpeciesShowFlg', {
                topicClassKey: topicClass.key,
                topicKey: topic.key
              })}}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(topic.speciesShowFlg),expression:"topic.speciesShowFlg"}],staticClass:"bottom-allow",attrs:{"src":require("@/assets/images/allow3.png")}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(!topic.speciesShowFlg),expression:"!topic.speciesShowFlg"}],staticClass:"right-allow",attrs:{"src":require("@/assets/images/allow4.png")}})]),_vm._v(" 参考基準値 ")]),(topic.speciesShowFlg === false)?_c('div',{staticClass:"collapsed",attrs:{"data-test":"collapsed"},on:{"click":function($event){return _vm.$emit('toggleSpeciesShowFlg', {
              topicClassKey: topicClass.key,
              topicKey: topic.key
            })}}},[_vm._v(" ... ")]):_c('div',{staticClass:"species-form"},_vm._l((topic.species),function(speciesItem){return _c('div',{key:speciesItem.speciesId,staticClass:"species-row"},[_c('examination-species-item',{attrs:{"species":speciesItem,"typeId":topic.typeId}})],1)}),0)])])})],2)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }