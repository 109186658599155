<template>
  <div class="examination-species-item">
    <div class="type numeric" v-if="typeId === 0">
      <validation-observer
        class="validation-observer"
        v-slot="{ errors }"
        tag="div"
      >
        <div class="form">
          <div class="label" :title="speciesName">
            <span>{{ speciesName }}</span>
          </div>
          <div class="inputs">
            <div class="text-input">
              <validation-provider class="validate" :rules="minRules" vid="min">
                <base-text-box
                  data-test="min-input"
                  :maxlength="24"
                  :placeholder="'下限'"
                  :styles="{ width: '120px' }"
                  :fullWidthNumbersToHalfWidthNumbers="true"
                  v-model="inputMin"
                  @input="focus('min')"
                  @blur="focus('min')"
                />
                <div v-if="showError(errors, 'min')" class="error">
                  <div v-for="(error, index) in errors.min" :key="index">
                    {{ error }}
                  </div>
                </div>
              </validation-provider>
            </div>
            <div class="dash">–</div>
            <div class="text-input">
              <validation-provider class="validate" :rules="maxRules" vid="max">
                <base-text-box
                  data-test="max-input"
                  :maxlength="24"
                  :placeholder="'上限'"
                  :styles="{ width: '120px' }"
                  :fullWidthNumbersToHalfWidthNumbers="true"
                  v-model="inputMax"
                  @input="focus('max')"
                  @blur="focus('max')"
                />
                <div v-if="showError(errors, 'max')" class="error">
                  <div v-for="(error, index) in errors.max" :key="index">
                    {{ error }}
                  </div>
                </div>
              </validation-provider>
            </div>
          </div>
        </div>
      </validation-observer>
    </div>
    <div class="type text" v-if="typeId === 1 || typeId === 2">
      <div class="form">
        <div class="label" :title="speciesName">
          <span>{{ speciesName }}</span>
        </div>
        <div class="inputs">
          <div class="text-input">
            <validation-provider
              class="validate"
              :rules="{ notSurrogatePair: true }"
              v-slot="{ errors }"
            >
              <base-text-box
                data-test="text-input"
                :maxlength="50"
                :placeholder="'50文字まで'"
                v-model="species.reference"
              />
              <div class="error">{{ errors[0] }}</div>
            </validation-provider>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseTextBox from '@/components/parts/atoms/BaseTextBox'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import '@/utils/validation_rules'

export default {
  name: 'ExaminationSpeciesItem',

  components: {
    BaseTextBox,
    ValidationProvider,
    ValidationObserver
  },

  props: {
    species: { type: Object },
    typeId: { type: Number }
  },

  data() {
    return {
      lastFocus: ''
    }
  },

  computed: {
    speciesName() {
      return this.$store.getters['species/getDataById'](this.species.speciesId)
        .name
    },
    min() {
      const reference = this.species.reference
      const numFlg = this.typeId === 0 && reference !== ''
      const value = numFlg ? reference.split(',')[0] : ''
      return value
    },
    max() {
      const reference = this.species.reference
      const numFlg = this.typeId === 0 && reference !== ''
      const value = numFlg ? reference.split(',')[1] : ''
      return value
    },
    minRules() {
      return this.isFloat(this.max)
        ? { floatRule: true, lessThan: this.max }
        : { floatRule: true }
    },
    maxRules() {
      return this.isFloat(this.min)
        ? { floatRule: true, greaterThan: this.min }
        : { floatRule: true }
    },
    inputMin: {
      get() {
        return this.min
      },
      set(val) {
        const min = val
        const max = this.max
        const reference = min === '' && max === '' ? '' : `${min},${max}`
        this.species.reference = reference
      }
    },
    inputMax: {
      get() {
        return this.max
      },
      set(val) {
        const min = this.min
        const max = val
        const reference = min === '' && max === '' ? '' : `${min},${max}`
        this.species.reference = reference
      }
    }
  },

  methods: {
    isFloat(value) {
      const regex = /^([1-9]\d*|0)(\.\d+)?$/
      const isFloat = regex.test(value)
      return isFloat
    },
    focus(target) {
      this.lastFocus = target
    },
    showError(errors, form) {
      const { min, max } = errors
      const focus = this.lastFocus
      const hasMinError = min?.length > 0
      const hasMaxError = max?.length > 0
      let show = false
      if (form === 'min') {
        show =
          (hasMinError && !hasMaxError) ||
          (focus === 'min' && hasMinError && hasMaxError)
      }
      if (form === 'max') {
        show =
          (!hasMinError && hasMaxError) ||
          (focus === 'max' && hasMinError && hasMaxError)
      }
      return show
    }
  }
}
</script>

<style lang="scss" scoped>
.examination-species-item {
  .validation-observer,
  .type.text {
    > .form {
      display: flex;
      align-items: center;
      > .label {
        font-size: 15px;
        width: 120px;
        margin-right: 10px;
        text-align: left;
        word-break: break-all;
      }
      > .inputs {
        padding: 20px 0;
        display: flex;
        align-items: center;
        > .text-input {
          @include validate-message();
        }
        > .dash {
          margin: 0 10px;
        }
      }
    }
    > .error {
      width: 360px;
      color: #{$tomato};
      font-size: 12px;
      text-align: left;
    }
  }
}
</style>
