<template>
  <div class="examination-edit">
    <validation-observer class="validation-observer" v-slot="{ invalid }">
      <div class="title">検査編集</div>
      <div class="contents">
        <div class="content-wrap">
          <div class="heading">基本項目</div>
          <div class="content">
            <examination-format-input-form
              class="input-form"
              :treatment="treatment"
              :format="format"
              :topicClasses="displayTopicClasses"
              @addTopicClass="addTopicClass"
              @addTopic="addTopic"
              @addOption="addOption"
              @removeTopic="removeTopic"
              @removeOption="removeOption"
              @selectType="selectType"
              @toggleSpeciesShowFlg="toggleSpeciesShowFlg"
            />
          </div>
        </div>
        <div class="button-wrap">
          <base-button-medium-white class="button" @click="goBack"
            >戻る</base-button-medium-white
          >
          <base-button-medium-orange
            class="button"
            v-if="lookOnlyFlg === 0"
            :disabled="invalid || waitFlg"
            @click="updateData"
            >登録</base-button-medium-orange
          >
        </div>
      </div>
      <announce-popup
        v-if="alertFlg"
        :title="title"
        :buttons="buttons"
        :type="type"
        @close="closeAlert"
        >{{ popupMessage }}</announce-popup
      >
      <unsaved-leave-popup />
    </validation-observer>
  </div>
</template>

<script>
import ExaminationFormatInputForm from '@/components/parts/organisms/ExaminationFormatInputForm'
import BaseButtonMediumWhite from '@/components/parts/atoms/BaseButtonMediumWhite'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import { ValidationObserver } from 'vee-validate'
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  name: 'ExaminationEdit',

  components: {
    ExaminationFormatInputForm,
    BaseButtonMediumWhite,
    BaseButtonMediumOrange,
    AnnouncePopup,
    UnsavedLeavePopup,
    ValidationObserver
  },

  mixins: [CheckInputDifference],

  props: {
    originalId: { type: Number }
  },

  data() {
    return {
      format: {},
      topicClassKey: 1,
      displayTopicClasses: [],
      topicKey: 1,
      displayTopics: [],
      optionKey: 1,
      alertFlg: false,
      buttons: ['閉じる'],
      treatment: {},
      popupMessage: '',
      title: '',
      type: '',
      waitFlg: false
    }
  },

  computed: {
    ...mapGetters({
      getTreatment: 'treatments/getDataByOriginalId',
      examinationFormats: 'examinationFormats/getData',
      examinationTopicClasses: 'examinationTopicClasses/getData',
      topicsByClassOriginalId:
        'examinationTopics/getDataByExaminationTopicClassOriginalId',
      examinationSpecies: 'examinationSpecies/getData',
      storeSpecies: 'species/getData',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    })
  },

  created() {
    this.treatment = { ...this.getTreatment(this.originalId) }
    this.format = {
      ...this.examinationFormats.find(
        v => v.treatmentOriginalId === this.treatment.originalId
      )
    }
    this.format.version++
    this.displayTopicClasses = this.makeDisplayTopicClasses()
    this.mixinInputData = {
      format: this.format,
      displayTopicClasses: this.displayTopicClasses
    }
    this.mixinSetInitialData()
  },

  methods: {
    goBack() {
      this.$router.push({
        path: `/settings/treatments/treatments/${this.originalId}/examinations/show`
      })
    },
    makeDisplayTopicClasses() {
      const topicClasses = this.examinationTopicClasses.filter(
        v =>
          v.examinationFormatOriginalId === this.format.originalId &&
          v.delFlg === 0
      )
      return topicClasses.map(topicClass => {
        return {
          ...topicClass,
          key: this.topicClassKey++,
          topics: this.makeDisplayTopics(topicClass.originalId)
        }
      })
    },
    makeDisplayTopics(topicClassOriginalId) {
      const examinationTopics = this.topicsByClassOriginalId(
        topicClassOriginalId
      )
      const topics = examinationTopics.map(topic => {
        const comments = JSON.parse(topic.comments)
        const displayOptions = comments.options.map(option => {
          return [...option, this.optionKey++]
        })
        return {
          ...topic,
          comments: { ...comments, options: displayOptions },
          species: this.makeDisplaySpecies(topic.originalId),
          typeId: this.typeConversion(topic.examinationType),
          key: this.topicKey++,
          speciesShowFlg: true
        }
      })
      return topics
    },
    makeDisplaySpecies(topicOriginalId) {
      const examinationSpecies = this.examinationSpecies.filter(
        v => v.examinationTopicOriginalId === topicOriginalId
      )
      const examinationSpeciesBySpeciesId = {}
      if (examinationSpecies.length !== 0) {
        examinationSpecies.forEach(
          s => (examinationSpeciesBySpeciesId[s.speciesId] = s)
        )
      }
      return this.storeSpecies.map(s => {
        return examinationSpeciesBySpeciesId[s.id] || this.baseSpecies(s.id)
      })
    },
    baseTopic() {
      const species = this.storeSpecies.map(v => {
        return this.baseSpecies(v.id)
      })
      return {
        id: 0,
        name: '',
        comments: this.baseComments(),
        species,
        unit: '',
        typeId: 0,
        examinationType: '数値',
        key: this.topicKey++,
        speciesShowFlg: true
      }
    },
    baseComments() {
      return {
        minMax: ['', ''],
        comment: '',
        options: [['', '', this.optionKey++]]
      }
    },
    baseSpecies(speciesId) {
      return { id: 0, speciesId, reference: '' }
    },
    async updateData() {
      this.waitFlg = true
      let topicClasses = _.cloneDeep(this.displayTopicClasses)
      topicClasses.forEach(topicClass => {
        let topics = []
        topicClass.topics.forEach(topic => {
          delete topic.key
          delete topic.speciesShowFlg
          delete topic.typeId
          topic.comments.options.forEach(o => o.splice(2, 1))
          topic.comments = JSON.stringify(topic.comments)
          topics.push(topic)
        })
        delete topicClass.key
      })
      const result = await this.$store.dispatch('examinationFormats/update', {
        examinationFormat: this.format,
        examinationTopicClasses: topicClasses
      })
      if (result === true) {
        this.mixinSetInitialData()
        this.type = 'success'
        this.title = '完了'
        this.popupMessage = '編集しました'
      } else {
        this.type = 'failure'
        this.title = '失敗'
        this.popupMessage = '編集に失敗しました'
      }
      this.waitFlg = false
      this.alertFlg = true
    },
    closeAlert() {
      this.alertFlg = false
      if (this.type === 'success') {
        this.$store.dispatch('petorelu/okLeave')
        this.goBack()
      }
    },
    typeConversion(val) {
      const types = ['数値', 'テキスト', '選択']
      if (typeof val === 'string') {
        return types.findIndex(v => v === val)
      } else {
        return types[val]
      }
    },
    addTopicClass() {
      this.displayTopicClasses.push({
        id: 0,
        name: '',
        key: this.topicClassKey++,
        topics: [this.baseTopic()]
      })
    },
    addTopic(topicClassKey) {
      const index = this.displayTopicClasses.findIndex(
        v => v.key === topicClassKey
      )
      const newTopics = [
        ...this.displayTopicClasses[index].topics,
        this.baseTopic()
      ]
      this.displayTopicClasses.splice(index, 1, {
        ...this.displayTopicClasses[index],
        topics: newTopics
      })
    },
    removeTopic({ topicClassKey, topicKey }) {
      const topicClassIndex = this.displayTopicClasses.findIndex(
        v => v.key === topicClassKey
      )
      const topics = this.displayTopicClasses[topicClassIndex].topics
      if (topics.length === 1) {
        this.displayTopicClasses.splice(topicClassIndex, 1)
      } else {
        const topicIndex = topics.findIndex(v => v.key === topicKey)
        topics.splice(topicIndex, 1)
        this.displayTopicClasses.splice(topicClassIndex, 1, {
          ...this.displayTopicClasses[topicClassIndex],
          topics
        })
      }
    },
    addOption({ topicClassKey, topicKey }) {
      const topicClassIndex = this.displayTopicClasses.findIndex(
        v => v.key === topicClassKey
      )
      const topics = this.displayTopicClasses[topicClassIndex].topics
      const comments = topics.find(v => v.key === topicKey).comments
      comments.options = [...comments.options, ['', '', this.optionKey++]]
      this.displayTopicClasses.splice(topicClassIndex, 1, {
        ...this.displayTopicClasses[topicClassIndex]
      })
    },
    removeOption({ topicClassKey, topicKey, optionKey }) {
      const topicClassIndex = this.displayTopicClasses.findIndex(
        v => v.key === topicClassKey
      )
      const topics = this.displayTopicClasses[topicClassIndex].topics
      const options = topics.find(v => v.key === topicKey).comments.options
      const optionIndex = options.findIndex(v => v[2] === optionKey)
      options.splice(optionIndex, 1)
      this.displayTopicClasses.splice(topicClassIndex, 1, {
        ...this.displayTopicClasses[topicClassIndex],
        topics
      })
    },
    selectType({ topicClassKey, topicKey, typeId }) {
      const topicClassIndex = this.displayTopicClasses.findIndex(
        v => v.key === topicClassKey
      )
      const topics = this.displayTopicClasses[topicClassIndex].topics
      const topicIndex = topics.findIndex(v => v.key === topicKey)
      const examinationType = this.typeConversion(typeId)
      const species = this.storeSpecies.map(v => {
        return this.baseSpecies(v.id)
      })
      topics.splice(topicIndex, 1, {
        ...topics[topicIndex],
        typeId,
        examinationType,
        comments: this.baseComments(),
        species
      })
      this.displayTopicClasses.splice(topicClassIndex, 1, {
        ...this.displayTopicClasses[topicClassIndex]
      })
    },
    toggleSpeciesShowFlg({ topicClassKey, topicKey }) {
      const topicClassIndex = this.displayTopicClasses.findIndex(
        v => v.key === topicClassKey
      )
      const topics = this.displayTopicClasses[topicClassIndex].topics
      const topic = topics.find(v => v.key === topicKey)
      topic.speciesShowFlg = !topic.speciesShowFlg
      this.displayTopicClasses.splice(topicClassIndex, 1, {
        ...this.displayTopicClasses[topicClassIndex]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.examination-edit {
  width: 100%;
  text-align: left;
  > .validation-observer {
    > .title {
      font-size: 20px;
      font-weight: bold;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
      width: 100%;
      min-width: 700px;
    }
    > .contents {
      margin-top: 20px;
      > .content-wrap {
        > .heading {
          font-size: 15px;
          font-weight: bold;
          border-bottom: 1px solid #{$light-grey};
          padding-bottom: 20px;
          min-width: 700px;
        }
        > .content {
          margin-top: 25px;
        }
      }
      > .button-wrap {
        display: flex;
        justify-content: center;
        margin: 60px 0;
        > .button {
          margin: 0 16px;
        }
      }
    }
  }
}
</style>
