<template>
  <div class="treatment-set-new">
    <validation-observer class="validation-observer" v-slot="{ invalid }">
      <div class="title">診療項目セット登録</div>
      <div class="contents">
        <div class="content-wrap">
          <div class="heading">基本項目</div>
          <div class="content">
            <treatment-set-input-form
              :treatmentSet="treatmentSet"
              :treatmentSetItems="treatmentSetItems"
              @decision="decision"
              @order="changeOrder"
              @click-trash-button="deleteTreatmentItem"
            />
          </div>
        </div>
        <div class="button-wrap">
          <base-button-medium-white class="button" @click="openListPage"
            >戻る</base-button-medium-white
          >
          <base-button-medium-orange
            class="button"
            v-if="lookOnlyFlg === 0"
            :disabled="invalid || treatmentSetItems.length === 0 || waitFlg"
            @click="createData"
            >登録</base-button-medium-orange
          >
        </div>
      </div>
      <announce-popup
        v-if="alertFlg"
        :title="title"
        :buttons="buttons"
        :type="type"
        @close="closeAlert"
        >{{ popupMessage }}</announce-popup
      >
      <unsaved-leave-popup />
    </validation-observer>
  </div>
</template>

<script>
import TreatmentSetInputForm from '@/components/parts/organisms/TreatmentSetInputForm'
import BaseButtonMediumWhite from '@/components/parts/atoms/BaseButtonMediumWhite'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import { ValidationObserver } from 'vee-validate'
import { mapGetters } from 'vuex'
import { removeKeyItemNameTotalPriceUnitTypeId } from '@/utils/property_filter'

export default {
  name: 'TreatmentSetNew',

  components: {
    TreatmentSetInputForm,
    BaseButtonMediumWhite,
    BaseButtonMediumOrange,
    AnnouncePopup,
    UnsavedLeavePopup,
    ValidationObserver
  },

  mixins: [CheckInputDifference],

  data() {
    return {
      treatmentSet: { name: '' },
      treatmentSetItems: [],
      num: 0,
      waitFlg: false,
      alertFlg: false,
      type: '',
      title: '',
      popupMessage: '',
      buttons: ['閉じる']
    }
  },

  computed: {
    ...mapGetters({
      treatments: 'treatments/getData',
      medicines: 'medicines/getData',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    })
  },

  created() {
    this.mixinInputData = {
      treatmentSet: this.treatmentSet,
      treatmentSetItems: this.treatmentSetItems
    }
    this.mixinSetInitialData()
  },

  methods: {
    openListPage() {
      this.$router.push({ path: '/settings/treatments/treatment-sets' })
    },
    decision(treatmentItem, editFlg) {
      if (editFlg) {
        const targetIndex = this.treatmentSetItems.findIndex(
          v => v.key === treatmentItem.key
        )
        this.treatmentSetItems.splice(targetIndex, 1, treatmentItem)
      } else {
        this.num++
        this.treatmentSetItems.push({ ...treatmentItem, key: this.num })
      }
    },
    changeOrder(data) {
      this.treatmentSetItems = data
    },
    deleteTreatmentItem(bodyDatum) {
      const targetIndex = this.treatmentSetItems.findIndex(
        v => v.key === bodyDatum.key
      )
      this.treatmentSetItems.splice(targetIndex, 1)
    },
    async createData() {
      const sendTreatmentSetItems = removeKeyItemNameTotalPriceUnitTypeId(
        this.treatmentSetItems
      )
      this.waitFlg = true
      const result = await this.$store.dispatch('treatmentSets/create', {
        treatmentSet: this.treatmentSet,
        treatmentSetItems: sendTreatmentSetItems
      })
      if (result === true) {
        this.type = 'success'
        this.title = '完了'
        this.popupMessage = '新規登録しました'
        this.mixinSetInitialData()
      } else if (result === 'no treatment') {
        this.type = 'failure'
        this.title = '失敗'
        this.popupMessage =
          '登録に失敗しました。\n選択した診療項目は既に削除されています。\n他の診療項目を選択して下さい。'
        this.resetTreatments()
      } else if (result === 'no medicine') {
        this.type = 'failure'
        this.title = '失敗'
        this.popupMessage =
          '登録に失敗しました。\n選択した薬剤物品は既に削除されています。\n他の薬剤物品を選択して下さい。'
        this.resetMedicines()
      } else {
        this.type = 'failure'
        this.title = '失敗'
        this.popupMessage = '登録に失敗しました'
      }
      this.waitFlg = false
      this.alertFlg = true
    },
    resetTreatments() {
      this.treatmentSetItems = this.treatmentSetItems.filter(v =>
        v.treatmentOriginalId
          ? this.treatments.some(o => o.originalId === v.treatmentOriginalId)
          : true
      )
    },
    resetMedicines() {
      this.treatmentSetItems = this.treatmentSetItems.filter(v =>
        v.medicineOriginalId
          ? this.medicines.some(o => o.originalId === v.medicineOriginalId)
          : true
      )
    },
    closeAlert() {
      this.alertFlg = false
      if (this.type === 'success') {
        this.$store.dispatch('petorelu/okLeave')
        this.openListPage()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.treatment-set-new {
  width: 100%;
  min-width: 614px;
  text-align: left;
  > .validation-observer {
    > .title {
      font-size: 20px;
      font-weight: bold;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
    }
    > .contents {
      margin-top: 20px;
      > .content-wrap {
        > .heading {
          font-size: 15px;
          font-weight: bold;
          border-bottom: 1px solid #{$light-grey};
          padding-bottom: 20px;
        }
        > .content {
          margin-top: 25px;
        }
      }
      > .button-wrap {
        display: flex;
        justify-content: center;
        margin: 60px 0;
        > .button {
          margin: 0 16px;
        }
      }
    }
  }
}
</style>
