<template>
  <div class="prescription-input-form">
    <div class="title">
      <base-button-trash
        :styles="{ width: '27px', height: '22px', marginLeft: '10px' }"
        @click="$emit('click')"
      />
      <div class="title-text">処方</div>
    </div>
    <div class="content">
      <validation-provider
        class="validation-provider"
        :rules="{ notSurrogatePair: true, maxRule: 255 }"
        v-slot="{ errors }"
      >
        <base-multiple-lines-text-box
          class="text-form"
          :maxlength="255"
          :styles="{ width: '560px', height: '55px' }"
          v-model="treatmentItem.prescription"
          :placeholder="'例 １回 ○ g １日△回 朝夕食後 ×日分'"
        />
        <div class="error">{{ errors[0] }}</div>
      </validation-provider>
    </div>
  </div>
</template>

<script>
import BaseButtonTrash from '@/components/parts/atoms/BaseButtonTrash'
import BaseMultipleLinesTextBox from '@/components/parts/atoms/BaseMultipleLinesTextBox.vue'
import { ValidationProvider } from 'vee-validate'
import '@/utils/validation_rules'

export default {
  name: 'PrescriptionInputForm',

  components: {
    BaseButtonTrash,
    BaseMultipleLinesTextBox,
    ValidationProvider
  },

  props: {
    treatmentItem: { type: Object }
  }
}
</script>

<style lang="scss" scoped>
.prescription-input-form {
  display: flex;
  width: 740px;
  height: 80px;
  box-sizing: border-box;
  border: solid 1px #{$light-grey};
  border-top: 0;
  > .title {
    display: flex;
    width: 150px;
    height: 100%;
    line-height: 80px;
    font-size: 13px;
    text-align: center;
    background-color: #{$very-light-shade-orange};
    > .title-text {
      margin-left: 25px;
    }
  }
  > .content {
    margin: 5px 0 0 20px;
    @include validate-message();
  }
}
</style>
