<template>
  <div
    class="treatment-set-item-list-table-row"
    @click="$emit('click-set-item', bodyDatum)"
  >
    <div class="row">
      <div class="row-item item-name" :title="itemName">
        <div class="item-text">{{ itemName }}</div>
      </div>
      <div class="row-item tax-exempt-flg" :title="taxExemptFlg">
        <div class="item-text">{{ taxExemptFlg }}</div>
      </div>
      <div class="row-item anicom-flg" :title="anicomFlg">
        <div class="item-text">{{ anicomFlg }}</div>
      </div>
      <div class="row-item ipet-flg" :title="ipetFlg">
        <div class="item-text">{{ ipetFlg }}</div>
      </div>
      <div class="row-item unit-price" :title="unitPrice">
        <div class="item-text">{{ unitPrice }}</div>
      </div>
      <div class="row-item amount" :title="amount">
        <div class="item-text">{{ amount }}</div>
      </div>
      <div class="row-item discount-rate" :title="discountRate">
        <div class="item-text">{{ discountRate }}</div>
      </div>
      <div class="row-item discount-price" :title="discountPrice">
        <div class="item-text">{{ discountPrice }}</div>
      </div>
      <div class="row-item total-price" :title="totalPrice">
        <div v-if="trashButtonFlg" class="item-text-total-price">
          <div class="item-text">{{ totalPrice }}</div>
          <div class="trash-button">
            <base-button-trash
              :styles="{ width: '27px', height: '22px' }"
              @click.stop="clickTrashButton"
            />
          </div>
        </div>
        <div v-else class="item-text">{{ totalPrice }}</div>
      </div>
    </div>
    <treatment-set-item-prescription
      v-if="bodyDatum.prescription"
      :prescription="bodyDatum.prescription"
    />
  </div>
</template>

<script>
import TreatmentSetItemPrescription from '@/components/parts/atoms/TreatmentSetItemPrescription.vue'
import BaseButtonTrash from '@/components/parts/atoms/BaseButtonTrash.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'TreatmentSetItemListTableRow',

  components: {
    TreatmentSetItemPrescription,
    BaseButtonTrash
  },

  props: {
    bodyDatum: { type: Object },
    trashButtonFlg: { type: Boolean, default: false }
  },

  computed: {
    ...mapGetters({
      treatmentByOId: 'treatments/getDataByOriginalId',
      medicineByOId: 'medicines/getDataByOriginalId'
    }),
    itemName() {
      const { treatmentOriginalId, medicineOriginalId } = this.bodyDatum
      const datum = treatmentOriginalId
        ? this.treatmentByOId(treatmentOriginalId)
        : this.medicineByOId(medicineOriginalId)
      return datum?.name || ''
    },
    taxExemptFlg() {
      return this.toCircleOrCross(this.bodyDatum.taxExemptFlg)
    },
    anicomFlg() {
      return this.toCircleOrCross(this.bodyDatum.anicomFlg)
    },
    ipetFlg() {
      return this.toCircleOrCross(this.bodyDatum.ipetFlg)
    },
    unitPrice() {
      return '￥' + this.bodyDatum.unitPrice.toLocaleString()
    },
    amount() {
      return this.bodyDatum.amount.toLocaleString()
    },
    discountRate() {
      return this.bodyDatum.discountRate.toLocaleString() + '%'
    },
    discountPrice() {
      return '￥' + this.bodyDatum.discountPrice.toLocaleString()
    },
    totalPrice() {
      return '￥' + this.bodyDatum.totalPrice.toLocaleString()
    }
  },

  methods: {
    toCircleOrCross(flg) {
      return flg === 1 ? '◯' : '✕'
    },
    clickTrashButton() {
      this.$emit('click-trash-button', this.bodyDatum)
    }
  }
}
</script>

<style lang="scss" scoped>
.treatment-set-item-list-table-row {
  > .row {
    display: flex;
    box-sizing: border-box;
    height: 30px;
    margin-top: 5px;
    margin-bottom: 5px;
    > .row-item {
      box-sizing: border-box;
      height: 100%;
      line-height: 30px;
      font-size: 13px;
      text-align: center;
      overflow: hidden;
      border-right: 1px solid #{$light-grey};
      &:first-child {
        > .item-text {
          margin-left: 5px;
          border-left: 1px solid #{$light-grey};
        }
      }
      &:last-child {
        border-right: none;
        > .item-text {
          margin-right: 5px;
          border-right: 1px solid #{$light-grey};
        }
      }
      &.item-name,
      &.total-price {
        width: 117px;
      }
      &.tax-exempt-flg,
      &.anicom-flg,
      &.ipet-flg {
        width: 78px;
      }
      &.unit-price,
      &.discount-price {
        width: 82px;
      }
      &.amount {
        width: 65px;
      }
      &.discount-rate {
        width: 80px;
      }

      > .item-text {
        box-sizing: border-box;
        height: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-top: 1px solid #{$light-grey};
        border-bottom: 1px solid #{$light-grey};
        background-color: #{$white};
      }
      > .item-text-total-price {
        display: flex;
        box-sizing: border-box;
        height: 100%;
        margin-right: 5px;
        border-top: 1px solid #{$light-grey};
        border-right: 1px solid #{$light-grey};
        border-bottom: 1px solid #{$light-grey};
        background-color: #{$white};
        > .item-text {
          width: 70%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        > .trash-button {
          width: 30%;
          margin-top: 1px;
        }
      }
    }
  }
}
</style>
