<template>
  <div class="examination-format-input-form">
    <div class="row text-row version">
      <div class="label">フォーマットNo</div>
      <div class="value">{{ format.version }}</div>
    </div>
    <div class="row text-row name">
      <div class="label">検査名</div>
      <div class="value">{{ treatment.name }}</div>
    </div>
    <div class="row blood-flg">
      <select-box-form
        :selectData="bloodFlgSelectData"
        v-model="format.bloodFlg"
        >血液検査管理</select-box-form
      >
    </div>
    <examination-topic-input-form
      :topicClasses="topicClasses"
      @addTopicClass="$emit('addTopicClass', $event)"
      @addTopic="$emit('addTopic', $event)"
      @addOption="$emit('addOption', $event)"
      @removeTopic="$emit('removeTopic', $event)"
      @removeOption="$emit('removeOption', $event)"
      @selectType="$emit('selectType', $event)"
      @toggleSpeciesShowFlg="$emit('toggleSpeciesShowFlg', $event)"
    />
  </div>
</template>

<script>
import ExaminationTopicInputForm from '@/components/parts/organisms/ExaminationTopicInputForm'
import SelectBoxForm from '@/components/parts/molecules/SelectBoxForm'

export default {
  name: 'ExaminationFormatInputForm',

  components: {
    ExaminationTopicInputForm,
    SelectBoxForm
  },

  props: {
    treatment: { type: Object },
    format: { type: Object },
    topicClasses: { type: Array }
  },

  data() {
    return {
      bloodFlgSelectData: [
        { id: 0, name: '✕' },
        { id: 1, name: '〇' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.examination-format-input-form {
  width: 100%;
  > .row {
    margin-bottom: 25px;
    > .select-box-form {
      ::v-deep .heading {
        width: 220px;
        margin-right: 20px;
        font-size: 15px;
      }
    }
  }
  > .text-row {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    font-size: 15px;
    > .label {
      width: 220px;
      margin-right: 20px;
    }
    > .value {
      flex: 1;
    }
  }
}
</style>
