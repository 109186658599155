<template>
  <div class="treatments-setting">
    <div class="wrap-content">
      <div class="fixed-display" v-if="checkPath">
        <div class="setting-title" data-test="title">診療項目</div>
        <div class="setting-tab-title-bar">
          <div v-for="titleObj in tabTitles" :key="titleObj.id">
            <base-setting-tab-title
              :title="titleObj.title"
              :link="titleObj.link"
              :names="titleObj.names"
            />
          </div>
        </div>
      </div>
      <div class="content-body">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import BaseSettingTabTitle from '@/components/parts/atoms/BaseSettingTabTitle.vue'
export default {
  name: 'TreatmentsSetting',
  components: {
    BaseSettingTabTitle
  },
  data() {
    return {
      tabTitles: [
        {
          id: 1,
          title: '診療項目',
          link: 'treatments',
          names: ['treatments']
        },
        {
          id: 2,
          title: '診療項目セット',
          link: 'treatment-sets',
          names: ['treatment-sets']
        }
      ]
    }
  },
  computed: {
    checkPath() {
      if (
        this.$route.params.originalId ||
        this.$route.params.id ||
        this.$route.path === '/settings/treatments/treatments/new' ||
        this.$route.path === '/settings/treatments/treatment-sets/new'
      ) {
        return false
      }
      return true
    }
  }
}
</script>
<style lang="scss" scoped>
.treatments-setting {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  text-align: left;
  > .wrap-content {
    > .fixed-display {
      position: sticky;
      position: -webkit-sticky;
      top: 24px;
      transform: translateY(-24px);
      padding-top: 24px;
      margin-bottom: -24px;
      z-index: 100;
      background-color: #{$white};
      > .setting-title {
        font-size: 20px;
        font-weight: bold;
      }
      > .setting-tab-title-bar {
        display: flex;
        flex-direction: row;
        height: 26px;
        margin-top: 20px;
        padding-bottom: -4px;
        border-bottom: 1px solid;
        border-color: #{$light-grey};
        box-sizing: border-box;
      }
    }
    > .content-body {
      min-width: 614px;
    }
  }
}
</style>
