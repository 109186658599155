<template>
  <div class="treatments">
    <div class="button-wrap" v-if="!sortModeFlg">
      <base-button-register
        class="button"
        v-if="lookOnlyFlg === 0"
        :text="'診療項目登録'"
        @click="goToNewPage"
      />
      <base-sort-button
        class="button"
        v-if="lookOnlyFlg === 0"
        @click="setSortMode"
      />
    </div>
    <div class="button-wrap" v-else>
      <base-button-small-white class="button" @click="cancelSortMode"
        >キャンセル</base-button-small-white
      >
      <base-button-small-orange
        class="button"
        v-if="lookOnlyFlg === 0"
        :disabled="waitFlg"
        @click="updateOrder"
        >並び順登録</base-button-small-orange
      >
    </div>
    <div class="content">
      <div class="heading">
        診療項目一覧<span v-if="sortModeFlg">{{ sortModeMessage }}</span>
      </div>
      <div class="area list">
        <slide-list
          ref="slideList"
          :bodyData="displayData"
          :bottomLayer="bottomLayer"
          :sortModeFlg="sortModeFlg"
          @order-parents="orderParents"
          @order-children="orderChildren"
          @order-grandchildren="orderGrandchildren"
          @add-child="goToNewPage('treatmentLargeClass', $event)"
          @add-grandchild="goToNewPage('treatmentClass', $event)"
          @delete-parent="openDeletePopup('treatmentLargeClasses', $event)"
          @delete-child="openDeletePopup('treatmentClasses', $event)"
          @edit-parent="gotoEditPage('treatment-large-classes', $event)"
          @edit-child="gotoEditPage('treatment-classes', $event)"
          @edit-grandchild="gotoEditPage('treatments', $event)"
          @select-grandchild="gotoShowPage"
        />
      </div>
    </div>
    <announce-popup
      v-if="popup.showFlg"
      :type="popup.type"
      :title="popup.title"
      :buttons="popup.buttons"
      :disabled="waitFlg"
      @close="closePopup"
      @cancel="popup.cancel"
      @decision="popup.decision"
      >{{ popup.message }}</announce-popup
    >
    <unsaved-leave-popup />
  </div>
</template>

<script>
import BaseButtonRegister from '@/components/parts/atoms/BaseButtonRegister'
import BaseSortButton from '@/components/parts/atoms/BaseSortButton'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseButtonSmallOrange from '@/components/parts/atoms/BaseButtonSmallOrange'
import SlideList from '@/components/parts/organisms/SlideList'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  name: 'Treatments',

  components: {
    BaseButtonRegister,
    BaseSortButton,
    BaseButtonSmallWhite,
    BaseButtonSmallOrange,
    SlideList,
    AnnouncePopup,
    UnsavedLeavePopup
  },

  mixins: [CheckInputDifference],

  data() {
    return {
      bottomLayer: 'grandchild',
      waitFlg: false,
      popup: {},
      targetId: 0,
      deletePopup: {
        showFlg: true,
        type: 'alert',
        title: '注意',
        buttons: ['削除しない', '削除する'],
        message: '削除してもよろしいですか？',
        cancel: () => {
          this.closePopup()
        }
      },
      sortModeFlg: false,
      sortModeMessage:
        'の並び順をドラッグ＆ドロップで変更した後、『並び順登録』ボタンを押して保存して下さい'
    }
  },

  computed: {
    ...mapGetters({
      treatmentLargeClasses: 'treatmentLargeClasses/getData',
      treatmentClasses: 'treatmentClasses/getData',
      classesByLargeOriginalId:
        'treatmentClasses/getDataByTreatmentLargeClassOriginalId',
      treatmentsByClassOriginalId:
        'treatments/getDataByTreatmentClassOriginalId',
      formatsByTreatmentOriginalId:
        'examinationFormats/getDataByTreatmentOriginalId',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    displayData() {
      return this.sortModeFlg
        ? this.mixinInputData
        : this.treatmentLargeClasses.map(largeClass => {
            const examinationFlg = Boolean(largeClass.examinationFlg)
            const parent =
              largeClass.defaultTreatmentLargeClassId === 0
                ? this.makeListDatum(largeClass)
                : this.makeListDatum(largeClass, examinationFlg, true)
            const treatmentClasses = this.classesByLargeOriginalId(
              largeClass.originalId
            )
            parent.children = treatmentClasses
              ? treatmentClasses.map(mediumClass => {
                  const child = this.makeListDatum(mediumClass, examinationFlg)
                  const treatments = this.treatmentsByClassOriginalId(
                    mediumClass.originalId
                  )
                  child.grandchildren = treatments
                    ? treatments.map(t => {
                        if (examinationFlg) {
                          const formats = this.formatsByTreatmentOriginalId(
                            t.originalId
                          )
                          return {
                            ...t,
                            examinationFlg: true,
                            noExaminationFlg: !(formats?.length > 0)
                          }
                        } else {
                          return { ...t, examinationFlg: false }
                        }
                      })
                    : []
                  return child
                })
              : []
            return parent
          })
    }
  },

  created() {
    this.initializePopup()
  },

  mounted() {
    this.openAccordion()
  },

  methods: {
    initializePopup() {
      this.popup = {
        showFlg: false,
        type: '',
        title: '',
        buttons: [],
        message: '',
        cancel: () => {},
        decision: () => {}
      }
    },
    makeListDatum(datum, examinationFlg = false, noChangeFlg = false) {
      return {
        id: datum.id,
        originalId: datum.originalId,
        name: datum.name,
        price: datum.price,
        anicomFlg: datum.anicomFlg,
        ipetFlg: datum.ipetFlg,
        showFlg: datum.showFlg,
        noChangeFlg,
        plusFlg: true,
        examinationFlg: examinationFlg
      }
    },
    setSortMode() {
      this.mixinInputData = _.cloneDeep(this.displayData)
      this.mixinSetInitialData()
      this.sortModeFlg = true
    },
    cancelSortMode() {
      this.mixinInputData = _.cloneDeep(this.displayData)
      this.mixinSetInitialData()
      this.sortModeFlg = false
    },
    orderParents(newOrderParents) {
      this.mixinInputData = newOrderParents
    },
    orderChildren(data) {
      this.$set(
        this.mixinInputData[data.parentIndex],
        'children',
        data.newOrderChildren
      )
    },
    orderGrandchildren(data) {
      this.$set(
        this.mixinInputData[data.parentIndex].children[data.childIndex],
        'grandchildren',
        data.newOrderGrandchildren
      )
    },
    async updateOrder() {
      this.waitFlg = true
      const idObj = {
        largeClassesOrderIds: [],
        classesOrderIds: [],
        orderIds: []
      }
      this.mixinInputData.forEach(v => {
        v.children.forEach(x => {
          x.grandchildren.forEach(y => {
            idObj.orderIds.push(y.id)
          })
          idObj.classesOrderIds.push(x.id)
        })
        idObj.largeClassesOrderIds.push(v.id)
      })
      const res = await this.$store.dispatch(
        'treatmentLargeClasses/order',
        idObj
      )
      if (res) {
        this.mixinSetInitialData()
        this.popup.type = 'success'
        this.popup.title = '完了'
        this.popup.message = '並び順を登録しました'
      } else {
        this.popup.type = 'failure'
        this.popup.title = '失敗'
        this.popup.message = '並び順登録に失敗しました'
      }
      this.popup.buttons = ['閉じる']
      this.popup.showFlg = true
      this.waitFlg = false
    },
    goToNewPage(from, id) {
      this.$router.push({ name: 'treatment-new', params: { from, id } })
    },
    gotoEditPage(className, originalId) {
      this.$router.push({ path: `${className}/${originalId}/edit` })
    },
    gotoShowPage(originalId) {
      this.$router.push({ name: 'treatment-show', params: { originalId } })
    },
    openDeletePopup(storeName, id) {
      this.popup = {
        ...this.deletePopup,
        decision: () => {
          this.delete(storeName, id)
        }
      }
    },
    async delete(storeName, id) {
      this.waitFlg = true
      const res = await this.$store.dispatch(`${storeName}/delete`, id)
      if (res === true) {
        this.popup.type = 'success'
        this.popup.title = '完了'
        this.popup.message = '削除しました'
      } else {
        this.popup.type = 'failure'
        this.popup.title = '失敗'
        this.popup.message =
          res === 'used in treatmentSetItem'
            ? '診療項目セットで使用されているため削除できません'
            : res === 'used in estimateTreatmentItem'
            ? '見積書で使用されているため削除できません'
            : '削除に失敗しました'
      }
      this.popup.buttons = ['閉じる']
      this.popup.showFlg = true
      this.waitFlg = false
    },
    closePopup() {
      this.initializePopup()
      if (this.sortModeFlg) {
        this.cancelSortMode()
      }
    },
    openAccordion() {
      if (this.$route.query.lc) {
        const refName1 = `parent${this.$route.query.lc}`
        const parent = this.$refs.slideList.$refs[refName1]
        if (parent) {
          parent[0].toggleChild()
          if (this.$route.query.c) {
            const refName2 = `child${this.$route.query.c}`
            const child = parent[0].$refs[refName2]
            if (child) {
              child[0].clickGrandchild()
            }
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.treatments {
  width: 100%;
  min-width: 614px;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 24px;
  > .button-wrap {
    display: flex;
    justify-content: flex-end;
    > .button {
      margin-left: 20px;
    }
  }
  > .content {
    margin-top: 16px;
    > .heading {
      margin-bottom: 16px;
      font-size: 15px;
      font-weight: bold;
    }
  }
}
</style>
