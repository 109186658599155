<template>
  <div class="treatment-set-input-form">
    <div class="row name">
      <text-input-form
        :requiredFlg="true"
        :maxlength="255"
        :placeholder="'255文字まで'"
        :styles="{ width: '265px' }"
        v-model="treatmentSet.name"
        >セット名</text-input-form
      >
    </div>
    <div class="row content">
      <div class="heading">内容<span class="require">必須</span></div>
      <div class="body">
        <div class="list">
          <div class="list-title">診療項目/薬剤物品一覧</div>
          <div class="search-area-component">
            <search-area
              :textBoxLabel="'検索単語'"
              :placeholder="''"
              :textValue="searchWord"
              v-model="searchWord"
              :searchAreaStyles="{ minWidth: '590px', width: '590px' }"
            />
          </div>
          <accordion-list
            v-show="searchWord.length === 0"
            :data="allList"
            :styles="{ height: '494px' }"
            @click-row="clickRow"
          />
          <accordion-list
            v-show="searchWord.length !== 0"
            :data="filteredList"
            :keyIndexFlg="true"
            :styles="{ height: '494px' }"
            @click-row="clickRow"
          />
        </div>
        <div class="treatment-set-items-area">
          <div class="title-area">
            <div class="title">セット項目</div>
          </div>
          <div class="treatment-set-items-list">
            <treatment-set-item-list-table
              :bodyData="treatmentSetItems"
              :sortModeFlg="true"
              :trashButtonFlg="true"
              @order="data => $emit('order', data)"
              @click-set-item="changeSetItem"
              @click-trash-button="
                bodyDatum => $emit('click-trash-button', bodyDatum)
              "
            />
          </div>
        </div>
      </div>
    </div>
    <treatment-medicine-popup
      v-if="popupFlg"
      :treatmentItem="treatmentItem"
      :editFlg="editFlg"
      @cancel="popupFlg = false"
      @decision="decision"
    />
  </div>
</template>

<script>
import TextInputForm from '@/components/parts/molecules/TextInputForm'
import SearchArea from '@/components/parts/molecules/SearchArea'
import AccordionList from '@/components/parts/organisms/AccordionList'
import TreatmentSetItemListTable from '@/components/parts/organisms/TreatmentSetItemListTable.vue'
import TreatmentMedicinePopup from '@/components/popups/TreatmentMedicinePopup'
import { mapGetters } from 'vuex'

export default {
  name: 'TreatmentSetInputForm',

  components: {
    TextInputForm,
    SearchArea,
    AccordionList,
    TreatmentSetItemListTable,
    TreatmentMedicinePopup
  },

  props: {
    treatmentSet: { type: Object },
    treatmentSetItems: { type: Array }
  },

  data() {
    return {
      searchWord: '',
      treatmentItem: {},
      popupFlg: false,
      editFlg: false
    }
  },

  computed: {
    ...mapGetters({
      treatmentLargeClasses: 'treatmentLargeClasses/getData',
      treatmentClasses: 'treatmentClasses/getData',
      treatments: 'treatments/getData',
      treatmentClassesByLargeOriginalId:
        'treatmentClasses/getDataByTreatmentLargeClassOriginalId',
      treatmentsByClassOriginalId:
        'treatments/getDataByTreatmentClassOriginalId',
      medicineLargeClasses: 'medicineLargeClasses/getData',
      medicineMediumClasses: 'medicineMediumClasses/getData',
      medicineSmallClasses: 'medicineSmallClasses/getData',
      medicines: 'medicines/getData',
      medicineMediumClassesByLargeOriginalId:
        'medicineMediumClasses/getDataByMedicineLargeClassOriginalId',
      medicineSmallClassesByMediumOriginalId:
        'medicineSmallClasses/getDataByMedicineMediumClassOriginalId',
      medicinesBySmallOriginalId:
        'medicines/getDataByMedicineSmallClassOriginalId',
      medicineByOriginalId: 'medicines/getDataByOriginalId'
    }),
    allList() {
      return [this.allTreatmentList, this.allMedicineList]
    },
    allTreatmentList() {
      const children = this.treatmentLargeClasses
        .filter(largeClass => largeClass.showFlg)
        .map(largeClass => {
          const treatmentClasses = this.treatmentClassesByLargeOriginalId(
            largeClass.originalId
          )
          const children = treatmentClasses
            ? treatmentClasses
                .filter(mediumClass => mediumClass.showFlg)
                .map(mediumClass => {
                  const treatments = this.treatmentsByClassOriginalId(
                    mediumClass.originalId
                  )
                  return {
                    ...mediumClass,
                    children: treatments
                      ? treatments
                          .filter(treatment => treatment.showFlg)
                          .map(treatment => {
                            return { ...treatment, layerNumber: 4 }
                          })
                      : [],
                    layerNumber: 3
                  }
                })
            : []
          return { ...largeClass, children, layerNumber: 2 }
        })
      return { name: '診療項目', children, layerNumber: 1 }
    },
    allMedicineList() {
      const children = this.medicineLargeClasses
        .filter(largeClass => largeClass.showFlg)
        .map(largeClass => {
          const mediumClasses = this.medicineMediumClassesByLargeOriginalId(
            largeClass.originalId
          )
          const children = mediumClasses
            ? mediumClasses
                .filter(mediumClass => mediumClass.showFlg)
                .map(mediumClass => {
                  const smallClasses = this.medicineSmallClassesByMediumOriginalId(
                    mediumClass.originalId
                  )
                  const grandchildren = smallClasses
                    ? smallClasses
                        .filter(smallClass => smallClass.showFlg)
                        .map(smallClass => {
                          const medicines = this.medicinesBySmallOriginalId(
                            smallClass.originalId
                          )
                          return {
                            ...smallClass,
                            children: medicines
                              ? medicines.filter(medicine => medicine.showFlg)
                              : [],
                            layerNumber: 4
                          }
                        })
                    : []
                  return {
                    ...mediumClass,
                    children: grandchildren,
                    layerNumber: 3
                  }
                })
            : []
          return { ...largeClass, children, layerNumber: 2 }
        })
      return { name: '薬剤物品', children, layerNumber: 1 }
    },
    filteredList() {
      return this.filteredTreatmentLargeClasses.concat(
        this.filteredTreatmentClasses,
        this.filteredTreatments,
        this.filteredMedicineLargeClasses,
        this.filteredMedicineMediumClasses,
        this.filteredMedicineSmallClasses,
        this.filteredMedicines
      )
    },
    filteredTreatmentLargeClasses() {
      if (this.searchWord.length === 0) return []
      return this.treatmentLargeClasses
        .filter(
          v =>
            v.showFlg &&
            v.name.toLowerCase().includes(this.searchWord.toLowerCase().trim())
        )
        .map(largeClass => {
          const treatmentClasses = this.treatmentClassesByLargeOriginalId(
            largeClass.originalId
          )
          const children = treatmentClasses
            ? treatmentClasses
                .filter(mediumClass => mediumClass.showFlg)
                .map(mediumClass => {
                  const treatments = this.treatmentsByClassOriginalId(
                    mediumClass.originalId
                  )
                  return {
                    ...mediumClass,
                    children: treatments
                      ? treatments
                          .filter(treatment => treatment.showFlg)
                          .map(treatment => {
                            return { ...treatment, layerNumber: 3 }
                          })
                      : [],
                    layerNumber: 2
                  }
                })
            : []
          return {
            ...largeClass,
            name: '診・大分類 / ' + largeClass.name,
            children,
            layerNumber: 1
          }
        })
    },
    filteredTreatmentClasses() {
      if (this.searchWord.length === 0) return []
      return this.treatmentClasses
        .filter(
          v =>
            v.showFlg &&
            v.name.toLowerCase().includes(this.searchWord.toLowerCase().trim())
        )
        .map(mediumClass => {
          const treatments = this.treatmentsByClassOriginalId(
            mediumClass.originalId
          )
          return {
            ...mediumClass,
            name: '診・分類 / ' + mediumClass.name,
            children: treatments
              ? treatments
                  .filter(treatment => treatment.showFlg)
                  .map(treatment => {
                    return { ...treatment, layerNumber: 2 }
                  })
              : [],
            layerNumber: 1
          }
        })
    },
    filteredTreatments() {
      if (this.searchWord.length === 0) return []
      return this.treatments
        .filter(
          v =>
            v.showFlg &&
            v.name.toLowerCase().includes(this.searchWord.toLowerCase().trim())
        )
        .map(treatment => {
          return {
            ...treatment,
            name: treatment.name,
            layerNumber: 1
          }
        })
    },
    filteredMedicineLargeClasses() {
      if (this.searchWord.length === 0) return []
      return this.medicineLargeClasses
        .filter(
          v =>
            v.showFlg &&
            v.name.toLowerCase().includes(this.searchWord.toLowerCase().trim())
        )
        .map(largeClass => {
          const mediumClasses = this.medicineMediumClassesByLargeOriginalId(
            largeClass.originalId
          )
          const children = mediumClasses
            ? mediumClasses
                .filter(mediumClass => mediumClass.showFlg)
                .map(mediumClass => {
                  const smallClasses = this.medicineSmallClassesByMediumOriginalId(
                    mediumClass.originalId
                  )
                  const grandchildren = smallClasses
                    ? smallClasses
                        .filter(smallClass => smallClass.showFlg)
                        .map(smallClass => {
                          const medicines = this.medicinesBySmallOriginalId(
                            smallClass.originalId
                          )
                          return {
                            ...smallClass,
                            children: medicines
                              ? medicines
                                  .filter(medicine => medicine.showFlg)
                                  .map(medicine => {
                                    return { ...medicine, layerNumber: 4 }
                                  })
                              : [],
                            layerNumber: 3
                          }
                        })
                    : []
                  return {
                    ...mediumClass,
                    children: grandchildren,
                    layerNumber: 2
                  }
                })
            : []
          return {
            ...largeClass,
            name: '薬・大分類 / ' + largeClass.name,
            children,
            layerNumber: 1
          }
        })
    },
    filteredMedicineMediumClasses() {
      if (this.searchWord.length === 0) return []
      return this.medicineMediumClasses
        .filter(
          v =>
            v.showFlg &&
            v.name.toLowerCase().includes(this.searchWord.toLowerCase().trim())
        )
        .map(mediumClass => {
          const smallClasses = this.medicineSmallClassesByMediumOriginalId(
            mediumClass.originalId
          )
          const grandchildren = smallClasses
            ? smallClasses
                .filter(smallClass => smallClass.showFlg)
                .map(smallClass => {
                  const medicines = this.medicinesBySmallOriginalId(
                    smallClass.originalId
                  )
                  return {
                    ...smallClass,
                    children: medicines
                      ? medicines
                          .filter(medicine => medicine.showFlg)
                          .map(medicine => {
                            return { ...medicine, layerNumber: 3 }
                          })
                      : [],
                    layerNumber: 2
                  }
                })
            : []
          return {
            ...mediumClass,
            name: '薬・中分類 / ' + mediumClass.name,
            children: grandchildren,
            layerNumber: 1
          }
        })
    },
    filteredMedicineSmallClasses() {
      if (this.searchWord.length === 0) return []
      return this.medicineSmallClasses
        .filter(
          v =>
            v.showFlg &&
            v.name.toLowerCase().includes(this.searchWord.toLowerCase().trim())
        )
        .map(smallClass => {
          const medicines = this.medicinesBySmallOriginalId(
            smallClass.originalId
          )
          return {
            ...smallClass,
            name: '薬・小分類 / ' + smallClass.name,
            children: medicines
              ? medicines
                  .filter(medicine => medicine.showFlg)
                  .map(medicine => {
                    return { ...medicine, layerNumber: 2 }
                  })
              : [],
            layerNumber: 1
          }
        })
    },
    filteredMedicines() {
      if (this.searchWord.length === 0) return []
      return this.medicines
        .filter(
          v =>
            v.showFlg &&
            v.name.toLowerCase().includes(this.searchWord.toLowerCase().trim())
        )
        .map(medicine => {
          return {
            ...medicine,
            name: medicine.name,
            layerNumber: 1
          }
        })
    }
  },

  methods: {
    clickRow(val) {
      this.popupFlg = true
      this.treatmentItem = {
        treatmentOriginalId: val.hasOwnProperty('defaultTreatmentId')
          ? val.originalId
          : 0,
        medicineOriginalId: val.hasOwnProperty('defaultTreatmentId')
          ? 0
          : val.originalId,
        unitTypeId: val.hasOwnProperty('defaultTreatmentId')
          ? 0
          : val.unitTypeId,
        unitPrice: val.hasOwnProperty('defaultTreatmentId')
          ? val.price
          : val.priceRate,
        amount: val.hasOwnProperty('defaultTreatmentId')
          ? 1
          : val.defaultVolume,
        taxExemptFlg: val.taxExemptFlg,
        anicomFlg: val.anicomFlg,
        ipetFlg: val.ipetFlg,
        discountRate: 0,
        discountPrice: 0,
        prescription: ''
      }
      this.editFlg = false
    },
    changeSetItem(bodyDatum) {
      this.popupFlg = true
      const unitTypeId = bodyDatum.medicineOriginalId
        ? this.medicineByOriginalId(bodyDatum.medicineOriginalId).unitTypeId
        : 0
      this.treatmentItem = { ...bodyDatum, unitTypeId }
      this.editFlg = true
    },
    decision(treatmentItem, editFlg) {
      this.$emit('decision', treatmentItem, editFlg)
      this.popupFlg = false
    }
  }
}
</script>

<style lang="scss" scoped>
.treatment-set-input-form {
  > .row {
    margin-top: 35px;
    &.name {
      width: 600px;
    }
    &.content {
      display: flex;
      > .heading {
        font-size: 15px;
        width: 240px;
        min-width: 240px;
        > .require {
          padding-left: 10px;
          font-size: 13px;
          color: #{$tomato};
        }
      }
      > .body {
        display: flex;
        > .list {
          > .list-title {
            font-size: 13px;
            font-weight: bold;
            margin-bottom: 20px;
          }
          > .search-area-component {
            margin-bottom: 11px;
          }
        }
        > .treatment-set-items-area {
          margin-left: 60px;
          > .title-area {
            display: flex;
            margin-bottom: 20px;
            > .title {
              font-size: 13px;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
</style>
