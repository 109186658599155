<template>
  <div class="treatment-set-item-list-table">
    <div class="table-header">
      <list-table-header-row
        :rowItems="listTableHeaderData"
        :headerRowStyles="headerRowStyles"
      />
    </div>
    <div
      class="table-body"
      ref="tableBody"
      :style="sortModeFlg ? { height: '548px' } : {}"
    >
      <div class="table-body-inner">
        <div
          v-if="sortModeFlg && listTableBodyData.length <= 2"
          class="explain-area-inside"
          data-test="explain-area-inside"
        >
          診療項目/薬剤物品一覧から項目を選択してセットを作成してください。
          セットした項目をドラッグすることで並び順の変更ができます。
        </div>
        <draggable
          class="draggable"
          v-if="sortModeFlg"
          v-model="listTableBodyData"
          @touchstart.native="startTimer"
          @touchmove.native="onMove"
          @touchend.native="stopTimer"
        >
          <treatment-set-item-list-table-row
            class="sort"
            v-for="bodyDatum in listTableBodyData"
            :key="bodyDatum.key"
            :bodyDatum="bodyDatum"
            :trashButtonFlg="trashButtonFlg"
            @click-set-item="bodyDatum => $emit('click-set-item', bodyDatum)"
            @click-trash-button="
              bodyDatum => $emit('click-trash-button', bodyDatum)
            "
          />
        </draggable>
        <div class="not-draggable" v-else>
          <treatment-set-item-list-table-row
            v-for="bodyDatum in listTableBodyData"
            :key="bodyDatum.key"
            :bodyDatum="bodyDatum"
            :trashButtonFlg="trashButtonFlg"
          />
        </div>
      </div>
    </div>
    <div
      v-if="sortModeFlg && listTableBodyData.length > 2"
      class="explain-area-outside"
      data-test="explain-area-outside"
    >
      ※診療項目/薬剤物品一覧から項目を選択してセットを作成してください。セットした項目をドラッグすることで並び順の変更ができます。
    </div>
  </div>
</template>

<script>
import ListTableHeaderRow from '@/components/parts/molecules/ListTableHeaderRow.vue'
import TreatmentSetItemListTableRow from '@/components/parts/molecules/TreatmentSetItemListTableRow.vue'
import Draggable from 'vuedraggable'

export default {
  name: 'TreatmentSetItemListTable',

  components: {
    ListTableHeaderRow,
    TreatmentSetItemListTableRow,
    Draggable
  },

  props: {
    bodyData: { type: Array },
    sortModeFlg: { type: Boolean, default: false },
    trashButtonFlg: { type: Boolean, default: false }
  },

  data() {
    return {
      headerData: [
        '標準',
        '項目名',
        '非課税',
        'アニコム',
        'アイペット',
        '単価（￥）',
        '数量',
        '割引（%）',
        '値引（￥）',
        '計（￥）'
      ],
      headerRowStyles: {
        backgroundColor: '#f0f0f0',
        fontWeight: 'bold',
        paddingRight: '0px'
      },
      headerItemStyleData: [
        {},
        {
          borderRight: '1px solid #cecece',
          lineHeight: '30px',
          fontSize: '13px',
          width: '117px'
        },
        {
          borderRight: '1px solid #cecece',
          lineHeight: '30px',
          fontSize: '13px',
          width: '78px'
        },
        {
          borderRight: '1px solid #cecece',
          lineHeight: '30px',
          fontSize: '13px',
          width: '78px'
        },
        {
          borderRight: '1px solid #cecece',
          lineHeight: '30px',
          fontSize: '13px',
          width: '78px'
        },
        {
          borderRight: '1px solid #cecece',
          lineHeight: '30px',
          fontSize: '13px',
          width: '82px'
        },
        {
          borderRight: '1px solid #cecece',
          lineHeight: '30px',
          fontSize: '13px',
          width: '65px'
        },
        {
          borderRight: '1px solid #cecece',
          lineHeight: '30px',
          fontSize: '13px',
          width: '80px'
        },
        {
          borderRight: '1px solid #cecece',
          lineHeight: '30px',
          fontSize: '13px',
          width: '82px'
        },
        { lineHeight: '30px', fontSize: '13px', width: '117px' }
      ],
      touchTime: 0,
      touchTimer: null,
      tableBodyPosition: { top: 0, right: 0, bottom: 0, left: 0 },
      touchPoint: { x: 0, y: 0 }
    }
  },

  computed: {
    listTableHeaderData() {
      return this.headerData.map((v, i) => {
        return {
          itemId: i + 1,
          itemText: v,
          itemStyle: this.headerItemStyleData[i]
        }
      })
    },
    listTableBodyData: {
      get() {
        return this.bodyData
      },
      set(data) {
        this.$emit('order', data)
      }
    },
    diffTouchTop() {
      return this.touchPoint.y - this.tableBodyPosition.top
    },
    diffTouchBottom() {
      return this.tableBodyPosition.bottom - this.touchPoint.y
    },
    diffTouchLeft() {
      return this.touchPoint.x - this.tableBodyPosition.left
    },
    diffTouchRight() {
      return this.tableBodyPosition.right - this.touchPoint.x
    },
    scrollUpFlg() {
      return this.diffTouchTop <= 30 &&
        this.diffTouchLeft >= 0 &&
        this.diffTouchRight >= 0
        ? true
        : false
    },
    scrollDownFlg() {
      return this.diffTouchBottom <= 30 &&
        this.diffTouchLeft >= 0 &&
        this.diffTouchRight >= 0
        ? true
        : false
    }
  },

  watch: {
    touchTime: function() {
      if (this.scrollUpFlg) {
        this.scrollUp()
      } else if (this.scrollDownFlg) {
        this.scrollDown()
      } else {
        return
      }
    }
  },

  beforeDestroy() {
    clearInterval(this.touchTimer)
  },

  methods: {
    setElementDimensions() {
      this.tableBodyPosition = {
        top: this.$refs.tableBody.getBoundingClientRect().top,
        bottom: this.$refs.tableBody.getBoundingClientRect().bottom,
        left: this.$refs.tableBody.getBoundingClientRect().left,
        right: this.$refs.tableBody.getBoundingClientRect().right
      }
    },
    onMove(e) {
      this.setElementDimensions()
      this.touchPoint = {
        x: e.touches[0].clientX,
        y: e.touches[0].clientY
      }
    },
    startTimer() {
      this.touchTimer = setInterval(() => {
        this.touchTime++
      }, 10)
    },
    stopTimer() {
      clearInterval(this.touchTimer)
      this.touchTime = 0
    },
    scrollDown() {
      this.$refs.tableBody.scrollBy(0, 5)
    },
    scrollUp() {
      this.$refs.tableBody.scrollBy(0, -5)
    }
  }
}
</script>

<style lang="scss" scoped>
.treatment-set-item-list-table {
  > .table-header {
    box-sizing: border-box;
    height: 30px;
    border: 1px solid #{$light-grey};
  }
  > .table-body {
    box-sizing: border-box;
    max-height: 548px;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: #{$plus_fa_gray};
    border: solid #{$light-grey};
    border-width: 0px 1px 1px 1px;
    position: relative;
    > .table-body-inner {
      width: 100%;
      width: 777px;
      > .explain-area-inside {
        position: absolute;
        bottom: 190px;
        left: 158px;
        width: 430px;
        font-size: 13px;
        text-align: center;
        color: #{$plus_c2_gray};
        z-index: 1;
      }
      > .draggable {
        position: absolute;
        z-index: 2;
        > .sort {
          cursor: move;
        }
      }
    }
  }
  > .explain-area-outside {
    font-size: 13px;
    color: #{$black_6};
    width: 435px;
  }
}
</style>
