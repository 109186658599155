<template>
  <div class="examination-topic-comment-row">
    <div class="label">コメント</div>
    <div class="number" v-if="typeId === 0">
      <text-input-form
        :placeholder="'通常時表示'"
        :styles="{ width: '265px' }"
        v-model="comments.comment"
        >{{ '通常時表示' }}</text-input-form
      >
      <div class="item">
        <text-input-form
          :placeholder="'下限未満時表示'"
          :styles="{ width: '265px', marginRight: '30px' }"
          v-model="comments.minMax[0]"
          >{{ '下限未満時表示' }}</text-input-form
        >
        <text-input-form
          :placeholder="'上限超過時表示'"
          :styles="{ width: '265px' }"
          v-model="comments.minMax[1]"
          >{{ '上限超過時表示' }}</text-input-form
        >
      </div>
    </div>
    <div class="text" v-if="typeId === 1">
      <text-input-form
        :placeholder="'通常時表示'"
        :styles="{ width: '265px' }"
        v-model="comments.comment"
        >{{ '通常時表示' }}</text-input-form
      >
    </div>
    <div class="select" v-if="typeId === 2">
      <div class="option">
        <text-input-form
          :placeholder="'通常時表示'"
          :styles="{ width: '265px', marginRight: '40px' }"
          v-model="comments.comment"
          >{{ '通常時表示' }}</text-input-form
        >
        <base-button-plus :text="'選択肢追加'" @click="$emit('addOption')" />
      </div>
      <div class="item" v-for="option in comments.options" :key="option[2]">
        <text-input-form
          :placeholder="'選択肢'"
          :styles="{ width: '265px', marginRight: '40px' }"
          :requiredFlg="true"
          v-model="option[0]"
          >{{ '選択肢' }}</text-input-form
        >
        <text-input-form
          :placeholder="'選択時コメント'"
          :styles="{ width: '265px', marginRight: '40px' }"
          v-model="option[1]"
          >{{ '選択時コメント' }}</text-input-form
        >
        <base-button-trash
          v-if="comments.options.length > 1"
          @click="$emit('removeOption', option[2])"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseButtonPlus from '@/components/parts/atoms/BaseButtonPlus'
import BaseButtonTrash from '@/components/parts/atoms/BaseButtonTrash'
import TextInputForm from '@/components/parts/molecules/TextInputForm'

export default {
  name: 'ExaminationTopicCommentRow',

  components: {
    BaseButtonPlus,
    BaseButtonTrash,
    TextInputForm
  },

  props: {
    comments: { type: [Object, String], default: '' },
    typeId: { type: Number }
  }
}
</script>

<style lang="scss" scoped>
.examination-topic-comment-row {
  display: flex;
  .text-input-form {
    ::v-deep .heading {
      width: 120px;
      margin-right: 20px;
    }
  }
  > .label {
    width: 220px;
    min-width: 220px;
    margin-right: 20px;
    font-size: 15px;
  }
  .option {
    display: flex;
    align-items: center;
  }
  .item {
    display: flex;
    margin-top: 20px;
    align-items: center;
  }
}
</style>
