<template>
  <div class="treatment-input-form">
    <div
      v-if="largeClassesSelectData.length === 0"
      class="row text-row treatment-large-class"
    >
      <div class="label">大分類名</div>
      <div class="value">{{ treatmentLargeClass.name }}</div>
    </div>
    <div v-else class="row text-row treatment-large-class">
      <select-box-text-form
        :label="'大分類名'"
        :maxlength="30"
        :placeholder="'30文字まで'"
        :requiredFlg="true"
        :selectData="largeClassesSelectData"
        :selectStyles="{ width: '200px' }"
        :selectValue="treatmentLargeClass.id"
        :textDisabled="Boolean(treatmentLargeClass.id)"
        :textStyles="{ width: '250px' }"
        :textValue="treatmentLargeClass.name"
        @select-input="
          $emit('select-input', { val: $event, field: 'treatmentLargeClass' })
        "
        @text-input="
          $emit('text-input', { val: $event, field: 'treatmentLargeClass' })
        "
      >
      </select-box-text-form>
    </div>
    <div
      v-if="classesSelectData.length === 0"
      class="row text-row treatment-class"
    >
      <div class="label">分類名</div>
      <div class="value">{{ treatmentClass.name }}</div>
    </div>
    <div v-else class="row text-row treatment-class">
      <select-box-text-form
        :label="'分類名'"
        :maxlength="30"
        :placeholder="'30文字まで'"
        :requiredFlg="true"
        :selectData="classesSelectData"
        :selectStyles="{ width: '200px' }"
        :selectValue="treatmentClass.id"
        :textDisabled="Boolean(treatmentClass.id)"
        :textStyles="{ width: '250px' }"
        :textValue="treatmentClass.name"
        @select-input="
          $emit('select-input', { val: $event, field: 'treatmentClass' })
        "
        @text-input="
          $emit('text-input', { val: $event, field: 'treatmentClass' })
        "
      >
      </select-box-text-form>
    </div>
    <div class="row treatment name">
      <text-input-form
        :maxlength="50"
        :placeholder="'50文字まで'"
        :requiredFlg="true"
        :styles="{ width: '350px' }"
        :value="treatment.name"
        @input="$emit('text-input', { val: $event, field: 'name' })"
        >診療項目名</text-input-form
      >
    </div>
    <div class="row treatment price">
      <text-input-form
        :maxlength="7"
        :styles="{ width: '350px' }"
        :validationRules="{ intRule: true }"
        :value="treatment.price"
        :fullWidthNumbersToHalfWidthNumbers="true"
        @input="numberInput($event, 'price')"
        @blur="blur('price')"
        >単価（￥）</text-input-form
      >
    </div>
    <div class="row treatment tax-exempt-flg">
      <select-box-form
        :requiredFlg="true"
        :selectData="taxExemptSelectData"
        :value="treatment.taxExemptFlg"
        @input="$emit('text-input', { val: $event, field: 'taxExemptFlg' })"
        >非課税</select-box-form
      >
    </div>
    <div class="row treatment anicom-flg">
      <select-box-form
        :requiredFlg="true"
        :selectData="anicomSelectData"
        :value="treatment.anicomFlg"
        @input="$emit('text-input', { val: $event, field: 'anicomFlg' })"
        >アニコム保険</select-box-form
      >
    </div>
    <div class="row treatment ipet-flg">
      <select-box-form
        :requiredFlg="true"
        :selectData="ipetSelectData"
        :value="treatment.ipetFlg"
        @input="$emit('text-input', { val: $event, field: 'ipetFlg' })"
        >アイペット保険</select-box-form
      >
    </div>
    <div class="row treatment show-flg">
      <select-box-form
        :requiredFlg="true"
        :selectData="showSelectData"
        :value="treatment.showFlg"
        @input="$emit('text-input', { val: $event, field: 'showFlg' })"
        >表示設定</select-box-form
      >
    </div>
  </div>
</template>

<script>
import SelectBoxForm from '@/components/parts/molecules/SelectBoxForm.vue'
import SelectBoxTextForm from '@/components/parts/molecules/SelectBoxTextForm.vue'
import TextInputForm from '@/components/parts/molecules/TextInputForm'
import { isNumber } from '@/utils/convert_string'

export default {
  name: 'TreatmentInputForm',

  components: {
    SelectBoxForm,
    SelectBoxTextForm,
    TextInputForm
  },

  props: {
    treatmentLargeClass: { type: Object },
    largeClassesSelectData: { type: Array, default: () => [] },
    treatmentClass: { type: Object },
    classesSelectData: { type: Array, default: () => [] },
    treatment: { type: Object }
  },

  data() {
    return {
      disabled: false,
      errorShowFlg: false,
      taxExemptSelectData: [
        { id: 0, name: '✕' },
        { id: 1, name: '〇' }
      ],
      anicomSelectData: [
        { id: 0, name: '✕' },
        { id: 1, name: '〇' }
      ],
      ipetSelectData: [
        { id: 0, name: '✕' },
        { id: 1, name: '〇' }
      ],
      showSelectData: [
        { id: 0, name: '✕' },
        { id: 1, name: '〇' }
      ]
    }
  },

  methods: {
    numberInput(val, field) {
      const numVal = val === '' ? '' : Number(val)
      val = isNumber(numVal) ? numVal : val
      this.$emit('text-input', { val, field })
    },
    blur(field) {
      if (this.treatment[field] === '') this.treatment[field] = 0
    }
  }
}
</script>

<style lang="scss" scoped>
.treatment-input-form {
  > .row {
    margin-bottom: 25px;
    > .select-box-form {
      display: flex;
      align-items: flex-start;
      > .heading {
        display: flex;
        align-items: center;
        height: 33px;
        width: 40%;
        font-size: 15px;
        > .text {
          display: flex;
        }
        > .required {
          margin-left: 10px;
          font-size: 13px;
          color: #{$tomato};
        }
      }
      > .body {
        width: 60%;
        display: flex;
        align-items: flex-start;
        @include validate-message();
      }
    }
  }
  > .text-row {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    font-size: 15px;
    > .label {
      width: 240px;
    }
    > .value {
      width: calc(100% - 240px);
    }
  }
}
</style>
