<template>
  <div class="treatment-show">
    <div class="list-link">
      <base-back-link @click="goToListPage" />
    </div>
    <div class="title">診療項目詳細</div>
    <div class="contents">
      <div class="button-area">
        <base-button-small-white
          class="button"
          v-if="lookOnlyFlg === 0 && examinationFlg && !examinationFormat"
          @click="goToExaminationPage"
          >検査登録</base-button-small-white
        >
        <base-button-small-white
          class="button"
          v-if="lookOnlyFlg === 0 && examinationFlg && examinationFormat"
          @click="goToExaminationPage"
          >検査詳細</base-button-small-white
        >
        <base-button-small-white
          class="button"
          v-if="lookOnlyFlg === 0"
          @click="goToEditPage"
          >編集</base-button-small-white
        >
        <base-button-small-red
          class="button"
          v-if="lookOnlyFlg === 0"
          @click="openDeletePopup"
          >削除</base-button-small-red
        >
      </div>
      <div class="basic-items-area">
        <div class="heading">基本項目</div>
        <div class="basic-items">
          <show-data-list
            :displayKeys="displayKeys"
            :keys="keys"
            :data="displayData"
          />
        </div>
      </div>
    </div>
    <announce-popup
      v-if="popupFlg"
      :title="title"
      :buttons="buttons"
      :disabled="waitFlg"
      :type="type"
      @cancel="cancelDelete"
      @decision="decideDelete"
      @close="closePopup"
      >{{ popupMessage }}</announce-popup
    >
  </div>
</template>

<script>
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import BaseBackLink from '@/components/parts/atoms/BaseBackLink'
import BaseButtonSmallRed from '@/components/parts/atoms/BaseButtonSmallRed'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import ShowDataList from '@/components/parts/organisms/ShowDataList.vue'
import SlideListRoute from '@/components/mixins/SlideListRoute'
import { mapGetters } from 'vuex'
import { toCircleOrCross } from '@/utils/convert_string'

export default {
  name: 'TreatmentShow',

  components: {
    BaseBackLink,
    BaseButtonSmallWhite,
    BaseButtonSmallRed,
    ShowDataList,
    AnnouncePopup
  },

  mixins: [SlideListRoute],

  props: {
    originalId: { type: Number }
  },

  data() {
    return {
      displayKeys: [
        '大分類名',
        '分類名',
        '診療項目名',
        '単価（￥）',
        '非課税',
        'アニコム保険',
        'アイペット保険',
        '表示設定'
      ],
      keys: [
        'largeClassName',
        'className',
        'name',
        'price',
        'taxExemptFlg',
        'anicomFlg',
        'ipetFlg',
        'showFlg'
      ],
      popupFlg: false,
      waitFlg: false,
      title: '',
      buttons: [],
      type: '',
      popupMessage: ''
    }
  },

  computed: {
    ...mapGetters({
      getTreatment: 'treatments/getDataByOriginalId',
      getTreatmentClass: 'treatmentClasses/getDataByOriginalId',
      getTreatmentLargeClass: 'treatmentLargeClasses/getDataByOriginalId',
      examinationFormats: 'examinationFormats/getData',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    treatment() {
      return this.getTreatment(this.originalId)
    },
    treatmentClass() {
      return this.treatment
        ? this.getTreatmentClass(this.treatment.treatmentClassOriginalId)
        : null
    },
    treatmentLargeClass() {
      return this.treatmentClass
        ? this.getTreatmentLargeClass(
            this.treatmentClass.treatmentLargeClassOriginalId
          )
        : null
    },
    displayData() {
      if (this.treatment) {
        return {
          id: this.treatment.originalId,
          largeClassName: this.treatmentLargeClass.name,
          className: this.treatmentClass.name,
          name: this.treatment.name,
          price: this.treatment.price.toLocaleString(),
          taxExemptFlg: toCircleOrCross(this.treatment.taxExemptFlg),
          anicomFlg: toCircleOrCross(this.treatment.anicomFlg),
          ipetFlg: toCircleOrCross(this.treatment.ipetFlg),
          showFlg: toCircleOrCross(this.treatment.showFlg)
        }
      } else {
        return {}
      }
    },
    examinationFlg() {
      return this.treatmentLargeClass?.examinationFlg === 1 ? true : false
    },
    examinationFormat() {
      return this.examinationFlg
        ? this.examinationFormats.find(
            v => v.treatmentOriginalId === this.treatment.originalId
          )
        : null
    }
  },
  methods: {
    goToListPage() {
      const query = this.mixinMakeTreatmentRouteQuery(
        'class',
        this.treatmentClass.originalId
      )
      this.$router.push({ name: 'treatments', query })
    },
    goToEditPage() {
      this.$router.push({ name: 'treatment-edit', params: this.originalId })
    },
    goToExaminationPage() {
      this.examinationFormat
        ? this.$router.push({
            name: 'examination-show',
            params: this.originalId
          })
        : this.$router.push({
            name: 'examination-new',
            params: this.originalId
          })
    },
    openDeletePopup() {
      this.type = 'alert'
      this.title = '注意'
      this.buttons = ['削除しない', '削除する']
      this.popupMessage = '削除してもよろしいですか？'
      this.popupFlg = true
    },
    cancelDelete() {
      this.popupFlg = false
    },
    async decideDelete() {
      this.waitFlg = true
      const id = this.getTreatment(this.originalId).id
      const result = await this.$store.dispatch('treatments/delete', id)
      if (result === true) {
        this.type = 'success'
        this.title = '完了'
        this.buttons = ['閉じる']
        this.popupMessage = '削除しました'
      } else {
        this.type = 'failure'
        this.title = '失敗'
        this.buttons = ['閉じる']
        this.popupMessage =
          result === 'used in treatmentSetItem'
            ? '診療項目セットで使用されているため削除できません'
            : result === 'used in estimateTreatmentItem'
            ? '見積書で使用されているため削除できません'
            : '削除に失敗しました'
      }
      this.waitFlg = false
    },
    closePopup() {
      this.popupFlg = false
      if (this.type === 'success') {
        this.$store.dispatch('petorelu/okLeave')
        this.goToListPage()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.treatment-show {
  width: 100%;
  min-width: 614px;
  text-align: left;
  > .list-link {
    margin-left: -26px;
  }
  > .title {
    font-size: 20px;
    font-weight: bold;
    border-bottom: 1px solid #{$light-grey};
    padding-bottom: 20px;
    margin-top: 20px;
  }
  > .contents {
    margin-top: 20px;
    > .button-area {
      display: flex;
      justify-content: flex-end;
      > .button {
        margin-left: 20px;
      }
    }
    > .basic-items-area {
      > .heading {
        font-size: 15px;
        font-weight: bold;
        border-bottom: 1px solid #{$light-grey};
        padding-bottom: 20px;
        margin-top: 20px;
      }
      > .basic-items {
        margin-top: 25px;
      }
    }
  }
}
</style>
