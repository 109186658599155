<template>
  <div class="treatment-set-show">
    <base-back-link class="base-back-link" @click="gotoListPage" />
    <div class="setting-title">診療項目セット詳細</div>
    <div class="button-wrapper">
      <div class="buttons">
        <base-button-small-white
          class="button"
          @click="gotoEditPage"
          v-if="lookOnlyFlg === 0"
          >編集</base-button-small-white
        >
        <base-button-small-red
          class="button"
          @click="openDeletePopup"
          v-if="lookOnlyFlg === 0"
          >削除</base-button-small-red
        >
      </div>
    </div>
    <div class="content">
      <div class="heading">基本項目</div>
      <div class="show-data-list">
        <show-data-list
          :displayKeys="displayKeys"
          :keys="keys"
          :data="treatmentSet"
        />
        <div class="treatment-set-items-area">
          <div class="treatment-set-items-content">
            内容
          </div>
          <div class="treatment-set-items-list">
            <treatment-set-item-list-table :bodyData="treatmentSetItems" />
          </div>
        </div>
      </div>
    </div>
    <announce-popup
      v-if="alertFlg"
      :title="title"
      :buttons="buttons"
      :disabled="waitFlg"
      :type="type"
      @cancel="alertFlg = false"
      @decision="decideDelete"
      @close="closePopup"
      >{{ popupMessage }}</announce-popup
    >
  </div>
</template>

<script>
import BaseBackLink from '@/components/parts/atoms/BaseBackLink'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseButtonSmallRed from '@/components/parts/atoms/BaseButtonSmallRed'
import ShowDataList from '@/components/parts/organisms/ShowDataList.vue'
import TreatmentSetItemListTable from '@/components/parts/organisms/TreatmentSetItemListTable.vue'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { mapGetters } from 'vuex'
export default {
  name: 'TreatmentSetShow',

  components: {
    BaseBackLink,
    BaseButtonSmallWhite,
    BaseButtonSmallRed,
    ShowDataList,
    TreatmentSetItemListTable,
    AnnouncePopup
  },

  props: {
    id: { type: Number }
  },

  data() {
    return {
      displayKeys: ['セット名'],
      keys: ['name'],
      waitFlg: false,
      alertFlg: false,
      title: '',
      buttons: [],
      type: '',
      popupMessage: ''
    }
  },

  computed: {
    ...mapGetters({
      getTreatmentSet: 'treatmentSets/getDataById',
      getTreatmentSetItemsByTreatmentSetId:
        'treatmentSetItems/getDataByTreatmentSetId',
      getTreatment: 'treatments/getDataByOriginalId',
      getMedicine: 'medicines/getDataByOriginalId',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    treatmentSet() {
      return this.getTreatmentSet(this.id) ? this.getTreatmentSet(this.id) : {}
    },
    treatmentSetItems() {
      const data = this.getTreatmentSetItemsByTreatmentSetId(this.id)
      return data
        ? data.map(v => {
            const datum = v.treatmentOriginalId
              ? this.getTreatment(v.treatmentOriginalId)
              : this.getMedicine(v.medicineOriginalId)
            const totalPrice =
              parseInt(v.unitPrice * v.amount * (1 - v.discountRate / 100)) -
              v.discountPrice
            return {
              ...v,
              itemName: datum.name,
              totalPrice
            }
          })
        : []
    }
  },

  methods: {
    gotoListPage() {
      this.$router.push({ path: '/settings/treatments/treatment-sets' })
    },
    gotoEditPage() {
      this.$router.push({
        path: `/settings/treatments/treatment-sets/${this.id}/edit`
      })
    },
    openDeletePopup() {
      this.title = '注意'
      this.buttons = ['削除しない', '削除する']
      this.type = 'alert'
      this.popupMessage = '削除してもよろしいですか？'
      this.alertFlg = true
    },
    async decideDelete() {
      this.waitFlg = true
      const result = await this.$store.dispatch('treatmentSets/delete', this.id)
      if (result === true) {
        this.type = 'success'
        this.title = '完了'
        this.buttons = ['閉じる']
        this.popupMessage = '削除しました'
      } else {
        this.type = 'failure'
        this.title = '失敗'
        this.buttons = ['閉じる']
        this.popupMessage = '削除に失敗しました'
      }
      this.waitFlg = false
    },
    closePopup() {
      this.alertFlg = false
      this.gotoListPage()
    }
  }
}
</script>

<style lang="scss" scoped>
.treatment-set-show {
  margin-bottom: 24px;
  > .base-back-link {
    margin-left: -26px;
  }
  > .setting-title {
    font-size: 20px;
    font-weight: bold;
    border-bottom: 1px solid #{$light-grey};
    margin-top: 20px;
    padding-bottom: 20px;
  }
  > .button-wrapper {
    margin-top: 20px;
    > .buttons {
      display: flex;
      justify-content: flex-end;
      > .button {
        margin-left: 20px;
      }
    }
  }
  > .content {
    margin-top: 16px;
    > .heading {
      font-size: 15px;
      font-weight: bold;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
    }
    > .show-data-list {
      margin-top: 25px;
      > .treatment-set-items-area {
        margin-top: 25px;
        display: flex;
        > .treatment-set-items-content {
          width: 240px;
          white-space: pre-wrap;
          box-sizing: border-box;
          padding-right: 20px;
          font-size: 15px;
        }
      }
    }
  }
}
</style>
