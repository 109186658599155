<template>
  <div class="examination-topic-input-form">
    <base-button-plus
      class="add-topic-class"
      :text="'項目区分追加'"
      @click="$emit('addTopicClass')"
    />
    <div
      class="topic-class-wrapper"
      v-for="topicClass in topicClasses"
      :key="topicClass.key"
    >
      <text-input-form
        :maxlength="255"
        :placeholder="'255文字まで'"
        :requiredFlg="topicClasses.length > 1"
        :styles="{ width: '350px', marginRight: '20px' }"
        v-model="topicClass.name"
        >項目区分名</text-input-form
      >
      <div
        class="topic-wrapper"
        v-for="topic in topicClass.topics"
        :class="{ only: topicClass.topics.length === 1 }"
        :key="topic.key"
      >
        <div class="row flex name">
          <text-input-form
            :maxlength="30"
            :placeholder="'30文字まで'"
            :requiredFlg="true"
            :styles="{ width: '350px', marginRight: '20px' }"
            v-model="topic.name"
            >項目名</text-input-form
          >
          <base-button-plus
            :styles="{ width: '125px', marginRight: '20px' }"
            :text="'項目追加'"
            @click="$emit('addTopic', topicClass.key)"
          />
          <base-button-trash
            v-if="topicClass.topics.length > 1 || topicClasses.length > 1"
            :styles="{ width: '125px' }"
            @click="
              $emit('removeTopic', {
                topicClassKey: topicClass.key,
                topicKey: topic.key
              })
            "
          />
        </div>
        <div class="row examination-type">
          <select-box-form
            :selectData="examinationTypeSelectData"
            :requiredFlg="true"
            v-model="topic.typeId"
            @input="
              $emit('selectType', {
                topicClassKey: topicClass.key,
                topicKey: topic.key,
                typeId: $event
              })
            "
            >入力タイプ</select-box-form
          >
        </div>
        <div class="row unit">
          <text-input-form
            :maxlength="30"
            :placeholder="'30文字まで'"
            :requiredFlg="topic.typeId === 0"
            :styles="{ width: '350px' }"
            v-model="topic.unit"
            >単位</text-input-form
          >
        </div>
        <div class="row comment">
          <examination-topic-comment-row
            :comments="topic.comments"
            :typeId="topic.typeId"
            @addOption="
              $emit('addOption', {
                topicClassKey: topicClass.key,
                topicKey: topic.key
              })
            "
            @removeOption="
              $emit('removeOption', {
                topicClassKey: topicClass.key,
                topicKey: topic.key,
                optionKey: $event
              })
            "
          />
        </div>
        <div class="row flex species">
          <div class="label">
            <span
              class="image-allow"
              data-test="image-allow"
              @click="
                $emit('toggleSpeciesShowFlg', {
                  topicClassKey: topicClass.key,
                  topicKey: topic.key
                })
              "
            >
              <img
                src="@/assets/images/allow3.png"
                class="bottom-allow"
                v-show="topic.speciesShowFlg"
              />
              <img
                src="@/assets/images/allow4.png"
                class="right-allow"
                v-show="!topic.speciesShowFlg"
              />
            </span>
            参考基準値
          </div>
          <div
            class="collapsed"
            data-test="collapsed"
            v-if="topic.speciesShowFlg === false"
            @click="
              $emit('toggleSpeciesShowFlg', {
                topicClassKey: topicClass.key,
                topicKey: topic.key
              })
            "
          >
            ...
          </div>
          <div class="species-form" v-else>
            <div
              class="species-row"
              v-for="speciesItem in topic.species"
              :key="speciesItem.speciesId"
            >
              <examination-species-item
                :species="speciesItem"
                :typeId="topic.typeId"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButtonPlus from '@/components/parts/atoms/BaseButtonPlus'
import BaseButtonTrash from '@/components/parts/atoms/BaseButtonTrash'
import TextInputForm from '@/components/parts/molecules/TextInputForm'
import SelectBoxForm from '@/components/parts/molecules/SelectBoxForm'
import ExaminationSpeciesItem from '@/components/parts/molecules/ExaminationSpeciesItem'
import ExaminationTopicCommentRow from '@/components/parts/molecules/ExaminationTopicCommentRow'

export default {
  name: 'ExaminationTopicInputForm',

  components: {
    BaseButtonPlus,
    BaseButtonTrash,
    SelectBoxForm,
    TextInputForm,
    ExaminationSpeciesItem,
    ExaminationTopicCommentRow
  },

  props: {
    topicClasses: { type: Array }
  },

  data() {
    return {
      examinationTypeSelectData: [
        { id: 0, name: '数値' },
        { id: 1, name: 'テキスト' },
        { id: 2, name: '選択' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.examination-topic-input-form {
  > .add-topic-class {
    position: relative;
    top: 54px;
    left: calc(100% - 175px);
  }
  > .topic-class-wrapper {
    background-color: #{$grey_f6};
    padding: 20px 40px;
    border-bottom: solid 30px #ffffff;
    &.only {
      > .text-input-form {
        visibility: hidden;
      }
    }
    > .topic-wrapper {
      padding-top: 20px;
      &:nth-child(n + 3) {
        border-top: dashed 2px #{$light-grey};
      }
      > .row {
        margin-bottom: 25px;
        > .label {
          width: 220px;
          min-width: 220px;
          margin-right: 20px;
          font-size: 15px;
          > .image-allow {
            display: inline-block;
            width: 16px;
            padding-right: 10px;
            cursor: pointer;
            > .bottom-allow {
              margin-top: 5px;
              width: 15px;
              height: 9px;
            }
            > .right-allow {
              width: 9px;
              height: 15px;
            }
          }
        }
        .species-form {
          background-color: #ffffff;
          width: 100%;
          > .species-row {
            margin: 0 27px;
            border-bottom: 1px solid #e0e0e0;
          }
        }
        .collapsed {
          text-align: center;
          text-justify: center;
          cursor: pointer;
          background-color: #ffffff;
          width: 100%;
        }
      }
      > .flex {
        display: flex;
        box-sizing: border-box;
      }
    }
  }
  .text-input-form,
  .select-box-form {
    ::v-deep .heading {
      width: 220px;
      margin-right: 20px;
      font-size: 15px;
    }
  }
}
@media (max-width: $tablet-width) {
  .label {
    width: 100px;
    margin-right: 20px;
    font-size: 15px;
  }
}
</style>
