<template>
  <div class="treatment-class-edit">
    <validation-observer class="validation-observer" v-slot="{ invalid }">
      <div class="title">{{ pageTitle }}</div>
      <div class="contents">
        <div class="content-wrap">
          <div class="heading">基本項目</div>
          <div class="content">
            <treatment-class-input-form
              class="input-form"
              :editClass="editClass"
              :target="target"
            />
          </div>
        </div>
        <div class="button-wrap">
          <base-button-medium-white class="button" @click="goToListPage"
            >戻る</base-button-medium-white
          >
          <base-button-medium-orange
            class="button"
            v-if="lookOnlyFlg === 0"
            :disabled="invalid || waitFlg"
            @click="updateData"
            >登録</base-button-medium-orange
          >
        </div>
      </div>
      <announce-popup
        v-if="alertFlg"
        :type="type"
        :title="title"
        :buttons="buttons"
        @close="closeAlert"
        >{{ popupMessage }}</announce-popup
      >
      <unsaved-leave-popup />
    </validation-observer>
  </div>
</template>

<script>
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import BaseButtonMediumWhite from '@/components/parts/atoms/BaseButtonMediumWhite'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import SlideListRoute from '@/components/mixins/SlideListRoute'
import TreatmentClassInputForm from '@/components/parts/organisms/TreatmentClassInputForm'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import { ValidationObserver } from 'vee-validate'
import { mapGetters } from 'vuex'

export default {
  name: 'TreatmentClassEdit',

  components: {
    AnnouncePopup,
    BaseButtonMediumOrange,
    BaseButtonMediumWhite,
    TreatmentClassInputForm,
    UnsavedLeavePopup,
    ValidationObserver
  },

  mixins: [CheckInputDifference, SlideListRoute],

  props: {
    originalId: { type: Number },
    target: { type: String }
  },

  data() {
    return {
      editClass: {},
      alertFlg: false,
      type: '',
      title: '',
      popupMessage: '',
      buttons: ['閉じる'],
      noDataFlg: false,
      outdatedFlg: false,
      waitFlg: false
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    storeName() {
      return this.target === 'treatment-classes'
        ? 'treatmentClasses'
        : 'treatmentLargeClasses'
    },
    targetName() {
      return this.target === 'treatment-classes'
        ? '診療項目分類'
        : '診療項目大分類'
    },
    pageTitle() {
      return this.targetName + '編集'
    }
  },

  created() {
    this.editClass = {
      ...this.$store.getters[`${this.storeName}/getDataByOriginalId`](
        this.originalId
      )
    }
    this.mixinInputData = this.editClass
    this.mixinSetInitialData()
  },

  methods: {
    goToListPage() {
      const mixinArguments =
        this.target === 'treatment-large-classes'
          ? ['largeClass', this.originalId]
          : this.target === 'treatment-classes'
          ? ['class', this.originalId]
          : []
      const query = this.mixinMakeTreatmentRouteQuery(...mixinArguments)
      this.$router.push({ name: 'treatments', query })
    },
    async updateData() {
      this.waitFlg = true
      const result = await this.$store.dispatch(
        `${this.storeName}/update`,
        this.editClass
      )
      if (result === true) {
        this.type = 'success'
        this.title = '完了'
        this.popupMessage = '編集しました'
        this.mixinSetInitialData()
      } else {
        this.type = 'failure'
        this.title = '失敗'
        if (result === 'already edited') {
          this.popupMessage = `編集に失敗しました。\n編集中の${this.targetName}は別端末で編集されました。`
          this.outdatedFlg = true
        } else if (result === 'already deleted') {
          this.popupMessage = `編集に失敗しました。\n編集中の${this.targetName}は既に削除されています。`
          this.noDataFlg = true
        } else if (result === 'no data in clinic') {
          this.popupMessage = `編集に失敗しました。\n${this.targetName}は既に削除されています。`
          this.noDataFlg = true
        } else {
          this.popupMessage = '編集に失敗しました'
        }
      }
      this.waitFlg = false
      this.alertFlg = true
    },
    closeAlert() {
      this.alertFlg = false
      if (this.type === 'success') {
        this.$store.dispatch('petorelu/okLeave')
        this.goToListPage()
      } else if (this.outdatedFlg) {
        this.$store.dispatch('petorelu/okLeave')
        this.goToListPage()
      } else if (this.noDataFlg) {
        this.$store.dispatch('petorelu/okLeave')
        this.goToListPage()
      }
      this.title = ''
      this.type = ''
      this.popupMessage = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.treatment-class-edit {
  width: 100%;
  min-width: 614px;
  text-align: left;
  > .validation-observer {
    > .title {
      font-size: 20px;
      font-weight: bold;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
    }
    > .contents {
      margin-top: 20px;
      > .content-wrap {
        > .heading {
          font-size: 15px;
          font-weight: bold;
          border-bottom: 1px solid #{$light-grey};
          padding-bottom: 20px;
        }
        > .content {
          margin-top: 25px;
          > .input-form {
            width: 600px;
          }
        }
      }
      > .button-wrap {
        display: flex;
        justify-content: center;
        margin: 60px 0;
        > .button {
          margin: 0 16px;
        }
      }
    }
  }
}
</style>
