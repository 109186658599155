<template>
  <div class="treatment-edit">
    <validation-observer class="validation-observer" v-slot="{ invalid }">
      <div class="title">診療項目編集</div>
      <div class="contents">
        <div class="content-wrap">
          <div class="heading">基本項目</div>
          <div class="content">
            <treatment-input-form
              class="input-form"
              :treatmentLargeClass="treatmentLargeClass"
              :treatmentClass="treatmentClass"
              :treatment="treatment"
              @text-input="handleTextInput"
            />
          </div>
        </div>
        <div class="button-wrap">
          <base-button-medium-white class="button" @click="goToShowPage"
            >戻る</base-button-medium-white
          >
          <base-button-medium-orange
            class="button"
            v-if="lookOnlyFlg === 0"
            :disabled="invalid || waitFlg"
            @click="updateData"
            >登録</base-button-medium-orange
          >
        </div>
      </div>
      <announce-popup
        v-if="alertFlg"
        :title="title"
        :buttons="buttons"
        :type="type"
        @close="closeAlert"
        >{{ popupMessage }}</announce-popup
      >
      <unsaved-leave-popup />
    </validation-observer>
  </div>
</template>

<script>
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import BaseButtonMediumWhite from '@/components/parts/atoms/BaseButtonMediumWhite'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import SlideListRoute from '@/components/mixins/SlideListRoute'
import TreatmentInputForm from '@/components/parts/organisms/TreatmentInputForm'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import { ValidationObserver } from 'vee-validate'
import { mapGetters } from 'vuex'

export default {
  name: 'TreatmentEdit',

  components: {
    AnnouncePopup,
    BaseButtonMediumOrange,
    BaseButtonMediumWhite,
    TreatmentInputForm,
    UnsavedLeavePopup,
    ValidationObserver
  },

  mixins: [CheckInputDifference, SlideListRoute],

  props: {
    originalId: { type: Number }
  },

  data() {
    return {
      alertFlg: false,
      buttons: ['閉じる'],
      treatmentLargeClass: {},
      treatmentClass: {},
      treatment: {},
      popupMessage: '',
      title: '',
      type: '',
      outdatedFlg: false,
      noDataFlg: false,
      waitFlg: false
    }
  },

  computed: {
    ...mapGetters({
      getTreatmentLargeClass: 'treatmentLargeClasses/getDataByOriginalId',
      getTreatmentClass: 'treatmentClasses/getDataByOriginalId',
      getTreatment: 'treatments/getDataByOriginalId',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    })
  },

  created() {
    this.treatment = { ...this.getTreatment(this.originalId) }
    this.treatmentClass = {
      ...this.getTreatmentClass(this.treatment.treatmentClassOriginalId)
    }
    this.treatmentLargeClass = {
      ...this.getTreatmentLargeClass(
        this.treatmentClass.treatmentLargeClassOriginalId
      )
    }
    this.mixinInputData = this.treatment
    this.mixinSetInitialData()
  },

  methods: {
    goToShowPage() {
      this.$router.push({ name: 'treatment-show', params: this.originalId })
    },
    goToListPage() {
      const query = this.mixinMakeTreatmentRouteQuery(
        'class',
        this.treatmentClass.originalId
      )
      this.$router.push({ name: 'treatments', query })
    },
    async updateData() {
      this.waitFlg = true
      const result = await this.$store.dispatch('treatments/update', {
        treatment: this.treatment
      })
      if (result === true) {
        this.type = 'success'
        this.title = '完了'
        this.popupMessage = '編集しました'
        this.mixinSetInitialData()
      } else {
        this.type = 'failure'
        this.title = '失敗'
        if (result === 'already edited') {
          this.popupMessage =
            '編集に失敗しました。\n編集中の診療項目は別端末で編集されました。'
          this.outdatedFlg = true
        } else if (result === 'already deleted') {
          this.popupMessage =
            '編集に失敗しました。\n編集中の診療項目は既に削除されています。'
          this.noDataFlg = true
        } else if (result === 'no data in clinic') {
          this.popupMessage =
            '編集に失敗しました。\n診療項目は既に削除されています。'
          this.noDataFlg = true
        } else {
          this.popupMessage = '編集に失敗しました'
        }
      }
      this.waitFlg = false
      this.alertFlg = true
    },
    closeAlert() {
      this.alertFlg = false
      if (this.type === 'success') {
        this.$store.dispatch('petorelu/okLeave')
        this.goToShowPage()
      } else if (this.outdatedFlg) {
        this.$store.dispatch('petorelu/okLeave')
        this.goToShowPage()
      } else if (this.noDataFlg) {
        this.$store.dispatch('petorelu/okLeave')
        this.goToListPage()
      }
      this.title = ''
      this.type = ''
      this.popupMessage = ''
    },
    handleTextInput(e) {
      this.treatment[e.field] = e.val
    }
  }
}
</script>

<style lang="scss" scoped>
.treatment-edit {
  width: 100%;
  text-align: left;
  > .validation-observer {
    > .title {
      font-size: 20px;
      font-weight: bold;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
      width: 100%;
      min-width: 700px;
    }
    > .contents {
      margin-top: 20px;
      > .content-wrap {
        > .heading {
          font-size: 15px;
          font-weight: bold;
          border-bottom: 1px solid #{$light-grey};
          padding-bottom: 20px;
          min-width: 700px;
        }
        > .content {
          margin-top: 25px;
          > .input-form {
            width: 600px;
          }
        }
      }
      > .button-wrap {
        display: flex;
        justify-content: center;
        margin: 60px 0;
        > .button {
          margin: 0 16px;
        }
      }
    }
  }
}
</style>
