<template>
  <div class="treatment-new">
    <validation-observer class="validation-observer" v-slot="{ invalid }">
      <div class="title">診療項目登録</div>
      <div class="contents">
        <div class="content-wrap">
          <div class="heading">基本項目</div>
          <div class="content">
            <treatment-input-form
              class="input-form"
              :treatmentLargeClass="treatmentLargeClass"
              :largeClassesSelectData="largeClassesSelectData"
              :treatmentClass="treatmentClass"
              :classesSelectData="classesSelectData"
              :treatment="treatment"
              @select-input="handleSelectInput"
              @text-input="handleTextInput"
            />
          </div>
        </div>
        <div class="button-wrap">
          <base-button-medium-white class="button" @click="goToListPage"
            >戻る</base-button-medium-white
          >
          <base-button-medium-orange
            class="button"
            v-if="lookOnlyFlg === 0"
            :disabled="invalid || waitFlg"
            @click="createData"
            >登録</base-button-medium-orange
          >
        </div>
      </div>
      <announce-popup
        v-if="alertFlg"
        :title="title"
        :buttons="buttons"
        :type="type"
        @close="closeAlert"
        >{{ popupMessage }}</announce-popup
      >
      <unsaved-leave-popup />
    </validation-observer>
  </div>
</template>

<script>
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import BaseButtonMediumWhite from '@/components/parts/atoms/BaseButtonMediumWhite'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import SlideListRoute from '@/components/mixins/SlideListRoute'
import TreatmentInputForm from '@/components/parts/organisms/TreatmentInputForm'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import _ from 'lodash'
import { ValidationObserver } from 'vee-validate'
import { mapGetters } from 'vuex'

export default {
  name: 'TreatmentNew',

  components: {
    AnnouncePopup,
    BaseButtonMediumOrange,
    BaseButtonMediumWhite,
    TreatmentInputForm,
    UnsavedLeavePopup,
    ValidationObserver
  },

  mixins: [CheckInputDifference, SlideListRoute],

  props: {
    from: { type: String },
    id: { type: Number }
  },

  data() {
    return {
      alertFlg: false,
      buttons: ['閉じる'],
      treatmentLargeClass: { id: 0, originalId: 0, name: '' },
      treatmentClass: { id: 0, originalId: 0, name: '' },
      treatment: {
        id: 0,
        originalId: 0,
        name: '',
        price: 0,
        taxExemptFlg: 0,
        anicomFlg: 1,
        ipetFlg: 1,
        showFlg: 1
      },
      largeClassesSelectData: [],
      classesSelectData: [],
      popupMessage: '',
      title: '',
      type: '',
      noDataFlg: false,
      waitFlg: false
    }
  },

  computed: {
    ...mapGetters({
      getLargeClass: 'treatmentLargeClasses/getDataById',
      getLargeClassByOriginalId: 'treatmentLargeClasses/getDataByOriginalId',
      largeClassesSelectDataZero: 'treatmentLargeClasses/selectDataZero',
      getClass: 'treatmentClasses/getDataById',
      classesByLargeOriginalId:
        'treatmentClasses/getDataByTreatmentLargeClassOriginalId',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    })
  },

  created() {
    if (this.from === 'treatmentLargeClass') {
      this.treatmentLargeClass = this.getLargeClass(this.id)
      this.classesSelectData = this.makeSelectData(
        this.classesByLargeOriginalId(this.treatmentLargeClass.originalId)
      )
    } else if (this.from === 'treatmentClass') {
      this.treatmentClass = this.getClass(this.id)
      this.treatmentLargeClass = this.getLargeClassByOriginalId(
        this.treatmentClass.treatmentLargeClassOriginalId
      )
    } else {
      this.largeClassesSelectData = _.cloneDeep(this.largeClassesSelectDataZero)
      this.classesSelectData = this.makeSelectData(
        this.classesByLargeOriginalId(this.treatmentLargeClass.originalId)
      )
    }
    this.mixinInputData = {
      treatmentLargeClass: this.treatmentLargeClass,
      treatmentClass: this.treatmentClass,
      treatment: this.treatment
    }
    this.mixinSetInitialData()
  },

  methods: {
    goToListPage() {
      const mixinArguments =
        this.from === 'treatmentLargeClass'
          ? ['largeClass', this.treatmentLargeClass.originalId]
          : this.from === 'treatmentClass'
          ? ['class', this.treatmentClass.originalId]
          : []
      const query = this.mixinMakeTreatmentRouteQuery(...mixinArguments)
      this.$router.push({ name: 'treatments', query })
    },
    makeSelectData(data) {
      const selectData = [{ id: 0, name: '新規分類名' }].concat(
        (data || []).map(v => {
          return { id: v.id, name: v.name }
        })
      )
      return _.cloneDeep(selectData)
    },
    async createData() {
      this.waitFlg = true
      const result = await this.$store.dispatch('treatments/create', {
        treatmentLargeClass: this.treatmentLargeClass,
        treatmentClass: this.treatmentClass,
        treatment: this.treatment
      })
      if (result === true) {
        this.type = 'success'
        this.title = '完了'
        this.popupMessage = '新規登録しました'
        this.mixinSetInitialData()
      } else {
        this.type = 'failure'
        this.title = '失敗'
        if (result === 'no data in clinic') {
          this.popupMessage =
            '登録に失敗しました。\n診療項目分類や診療項目大分類は既に削除されています。'
          this.noDataFlg = true
        } else if (result === 'no treatmentClass') {
          this.noDataFlg = true
          this.popupMessage =
            '登録に失敗しました。\n診療項目分類は別端末で編集または削除されました。'
        } else if (result === 'no treatmentLargeClass') {
          this.noDataFlg = true
          this.popupMessage =
            '登録に失敗しました。\n診療項目大分類は別端末で編集または削除されました。'
        } else {
          this.popupMessage = '登録に失敗しました'
        }
      }
      this.waitFlg = false
      this.alertFlg = true
    },
    closeAlert() {
      this.alertFlg = false
      if (this.type === 'success') {
        this.$store.dispatch('petorelu/okLeave')
        this.goToListPage()
      } else if (this.noDataFlg) {
        this.$store.dispatch('petorelu/okLeave')
        this.goToListPage()
      }
      this.title = ''
      this.type = ''
      this.popupMessage = ''
    },
    handleSelectInput(e) {
      if (e.field === 'treatmentLargeClass') {
        if (e.val === 0) {
          this.treatmentLargeClass = { id: 0, originalId: 0, name: '' }
          this.treatmentClass = { id: 0, originalId: 0, name: '' }
          this.mixinInputData.treatmentLargeClass = this.treatmentLargeClass
          this.mixinInputData.treatmentClass = this.treatmentClass
        } else {
          const target = this.getLargeClass(e.val)
          Object.keys(target).forEach(key => {
            this.treatmentLargeClass[key] = target[key]
          })
          this.treatmentClass = { id: 0, originalId: 0, name: '' }
        }
        this.classesSelectData = this.makeSelectData(
          this.classesByLargeOriginalId(this.treatmentLargeClass.originalId)
        )
      } else if (e.field === 'treatmentClass') {
        if (e.val === 0) {
          this.treatmentClass = { id: 0, originalId: 0, name: '' }
        } else {
          const target = this.getClass(e.val)
          Object.keys(target).forEach(key => {
            this.treatmentClass[key] = target[key]
          })
        }
      }
    },
    handleTextInput(e) {
      if (e.field === 'treatmentLargeClass') {
        this.treatmentLargeClass.name = e.val
      } else if (e.field === 'treatmentClass') {
        this.treatmentClass.name = e.val
      } else {
        this.treatment[e.field] = e.val
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.treatment-new {
  width: 100%;
  text-align: left;
  > .validation-observer {
    > .title {
      font-size: 20px;
      font-weight: bold;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
      width: 100%;
      min-width: 700px;
    }
    > .contents {
      margin-top: 20px;
      > .content-wrap {
        > .heading {
          font-size: 15px;
          font-weight: bold;
          border-bottom: 1px solid #{$light-grey};
          padding-bottom: 20px;
          min-width: 700px;
        }
        > .content {
          margin-top: 25px;
          > .input-form {
            width: 600px;
          }
        }
      }
      > .button-wrap {
        display: flex;
        justify-content: center;
        margin: 60px 0;
        > .button {
          margin: 0 16px;
        }
      }
    }
  }
}
</style>
