<template>
  <div class="treatment-medicine-form-in-popup">
    <div class="treatment-medicine-form">
      <div class="table-header">
        <div class="row item-name">診療内容</div>
        <div class="row tax-exempt-flg">非課税</div>
        <div class="row anicom-flg">アニコム</div>
        <div class="row ipet-flg">アイペット</div>
        <div class="row unit-price">単価（￥）</div>
        <div class="row amount">数量</div>
        <div class="row discount-rate">割引（%）</div>
        <div class="row discount-price">値引（￥）</div>
        <div class="row total-price }">計（￥）</div>
      </div>
      <div class="table-body">
        <div class="row item-name" :title="itemName">{{ itemName }}</div>
        <div class="row tax-exempt-flg">
          <base-select-box
            :selectData="selectData"
            :styles="{ height: '28px' }"
            v-model="treatmentItem.taxExemptFlg"
          />
        </div>
        <div class="row anicom-flg">
          <base-select-box
            :selectData="selectData"
            :styles="{ height: '28px' }"
            v-model="treatmentItem.anicomFlg"
          />
        </div>
        <div class="row ipet-flg">
          <base-select-box
            :selectData="selectData"
            :styles="{ height: '28px' }"
            v-model="treatmentItem.ipetFlg"
          />
        </div>
        <div class="row unit-price">
          <validation-provider
            class="validate-provider"
            :rules="{ intRule: true }"
            v-slot="{ errors }"
            ><base-text-box
              :value="treatmentItem.unitPrice"
              :maxlength="7"
              :styles="textBoxStyles"
              :fullWidthNumbersToHalfWidthNumbers="true"
              @input="inputNum($event, 'unitPrice')"
              @blur="blur('unitPrice')"
            />
            <div v-if="errors[0]" class="error">{{ errors[0] }}</div>
          </validation-provider>
        </div>
        <div class="row amount">
          <validation-provider
            class="validate-provider"
            :rules="amountValidation"
            v-slot="{ errors }"
            ><base-text-box
              :value="treatmentItem.amount"
              :maxlength="5"
              :styles="textBoxStylesNarrow"
              :fullWidthNumbersToHalfWidthNumbers="true"
              @input="inputNum($event, 'amount')"
              @blur="blur('amount')"
            />
            <div v-if="errors[0]" class="error">{{ errors[0] }}</div>
          </validation-provider>
        </div>
        <div class="row discount-rate">
          <validation-provider
            class="validate-provider"
            :rules="{ intRule: true, percentRule: true }"
            v-slot="{ errors }"
            ><base-text-box
              :value="treatmentItem.discountRate"
              :maxlength="3"
              :styles="textBoxStylesNarrow"
              :fullWidthNumbersToHalfWidthNumbers="true"
              @input="inputNum($event, 'discountRate')"
              @blur="blur('discountRate')"
            />
            <div v-if="errors[0]" class="error">{{ errors[0] }}</div>
          </validation-provider>
        </div>
        <div class="row discount-price">
          <validation-provider
            class="validate-provider"
            :rules="{ intRule: true }"
            v-slot="{ errors }"
            ><base-text-box
              :value="treatmentItem.discountPrice"
              :maxlength="7"
              :styles="textBoxStyles"
              :fullWidthNumbersToHalfWidthNumbers="true"
              @input="inputNum($event, 'discountPrice')"
              @blur="blur('discountPrice')"
            />
            <div v-if="errors[0]" class="error">{{ errors[0] }}</div>
          </validation-provider>
        </div>
        <div class="row total-price }">
          {{ '￥' + totalPrice.toLocaleString() }}
        </div>
      </div>
    </div>
    <base-button-plus
      v-if="
        this.treatmentItem.medicineOriginalId !== 0 && !prescriptionDisplayFlg
      "
      class="register-button-area"
      :text="'処方追加'"
      @click="$emit('click')"
    />
  </div>
</template>

<script>
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import BaseTextBox from '@/components/parts/atoms/BaseTextBox'
import BaseButtonPlus from '@/components/parts/atoms/BaseButtonPlus'
import { mapGetters } from 'vuex'
import { ValidationProvider } from 'vee-validate'
import '@/utils/validation_rules'

export default {
  name: 'TreatmentMedicineFormInPopup',

  components: {
    BaseSelectBox,
    BaseTextBox,
    BaseButtonPlus,
    ValidationProvider
  },

  props: {
    treatmentItem: { type: Object },
    totalPrice: { type: Number },
    prescriptionDisplayFlg: { type: Boolean, default: false }
  },

  data() {
    return {
      selectData: [
        { id: 1, name: '〇' },
        { id: 0, name: '✕' }
      ],
      textBoxStyles: {
        width: '70px',
        height: '28px',
        textAlign: 'right',
        padding: '2px 4px'
      },
      textBoxStylesNarrow: {
        width: '55px',
        height: '28px',
        textAlign: 'right',
        padding: '2px 4px'
      }
    }
  },

  computed: {
    ...mapGetters({
      treatmentByOId: 'treatments/getDataByOriginalId',
      medicineByOId: 'medicines/getDataByOriginalId'
    }),
    amountValidation() {
      return this.treatmentItem.treatmentOriginalId !== 0
        ? { intRule: true }
        : { floatRule: true }
    },
    itemName() {
      const { treatmentOriginalId, medicineOriginalId } = this.treatmentItem
      const datum = treatmentOriginalId
        ? this.treatmentByOId(treatmentOriginalId)
        : this.medicineByOId(medicineOriginalId)
      return datum?.name || ''
    }
  },

  methods: {
    blur(field) {
      if (this.treatmentItem[field] === '') this.treatmentItem[field] = 0
    },
    inputNum(value, field) {
      const isInt = /^([0-9]\d*)$/.test(value)
      const newValue = isInt ? Number(value) : value
      this.treatmentItem[field] = newValue
    }
  }
}
</script>

<style lang="scss" scoped>
.treatment-medicine-form-in-popup {
  margin: 27px 0 0 0;
  > .treatment-medicine-form {
    box-sizing: border-box;
    width: 740px;
    height: 66px;
    border: solid 1px #{$light-grey};
    > .table-header {
      display: flex;
      height: 29px;
      border-bottom: solid 1px #{$light-grey};
      background-color: #{$very-light-shade-orange};
      font-feature-settings: 'palt';
      > .row {
        border-right: solid 1px #{$light-grey};
        width: 80px;
        font-size: 13px;
        letter-spacing: 1.3px;
        line-height: 29px;
        text-align: center;
        &.item-name {
          width: 160px;
        }
        &.tax-exempt-flg,
        &.anicom-flg {
          width: 60px;
        }
        &.ipet-flg {
          width: 70px;
        }
        &.amount,
        &.discount-rate {
          width: 65px;
        }
        &.total-price {
          border-right: none;
          width: 100px;
        }
      }
    }
    > .table-body {
      display: flex;
      height: 35px;
      > .row {
        border-right: solid 1px #{$light-grey};
        width: 80px;
        font-size: 13px;
        letter-spacing: 1.3px;
        line-height: 35px;
        text-align: center;
        > .validate-provider {
          position: relative;
          > .error {
            position: absolute;
            z-index: 10;
            height: 20px;
            line-height: 15px;
            background-color: #{$white};
            color: #{$tomato};
            font-size: 12px;
            text-align: left;
            white-space: nowrap;
          }
        }
        &.item-name {
          width: 160px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &.tax-exempt-flg,
        &.anicom-flg {
          width: 60px;
          line-height: 32px;
        }
        &.ipet-flg {
          width: 70px;
          line-height: 32px;
        }
        &.amount,
        &.discount-rate {
          width: 65px;
        }
        &.total-price {
          border-right: none;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100px;
        }
      }
    }
  }
  > .register-button-area {
    margin: 5px 0 0 5px;
  }
}
</style>
