<template>
  <div class="treatment-set-item-prescription">
    <div class="title-area"><div class="title">処方</div></div>
    <div class="content-area">
      <div class="content">{{ prescription }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TreatmentSetItemPrescription',

  props: {
    prescription: { type: String }
  }
}
</script>

<style lang="scss" scoped>
.treatment-set-item-prescription {
  display: flex;
  box-sizing: border-box;
  min-height: 30px;
  margin-top: -5px;
  margin-bottom: 5px;
  font-size: 13px;
  > .title-area {
    width: 117px;
    > .title {
      box-sizing: border-box;
      height: 100%;
      margin-left: 5px;
      padding-top: 5px;
      background-color: #{$white_f7};
      border-left: 1px solid #{$light-grey};
      border-right: 1px solid #{$light-grey};
      border-bottom: 1px solid #{$light-grey};
      font-weight: bold;
      text-align: center;
    }
  }
  > .content-area {
    width: 660px;
    > .content {
      box-sizing: border-box;
      height: 100%;
      padding-left: 10px;
      padding-top: 5px;
      margin-right: 5px;
      border-right: 1px solid #{$light-grey};
      border-bottom: 1px solid #{$light-grey};
      background-color: #{$white};
    }
  }
}
</style>
